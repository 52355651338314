import { Action, action, Thunk, thunk } from 'easy-peasy'
import {
    addMoveToGTPosition,
    createRootGameTree,
    getMoveById,
    getNextPosition,
    getPositionById,
    getStartingPosition,
} from '../../chess/gameTree'
import { createPuzzlePGNString, gameFromPGN } from '../../chess/pgn'
import { BasePositionFEN } from '../../chess/positionPresets'
import { Color, GameTree, GameTreePosition, Move } from '../../chess/types'
import { Square } from '../../react-chessboard/src/chessboard/types'
import { analyticsManager } from '../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { PuzzleStatus } from './../PuzzlesView/functions/puzzlesApi'
import { getPuzzleQuest, resolvePuzzle } from './API'
import { getReward } from './common'
import { DayProgress, QuestPuzzle } from './types'

export const autoFeedTimeout = 15000
export const autoMoveTimeout = 1000

export interface PuzzleQuestModel {
    puzzleId: string
    colorToMove: number
    currentPosition: GameTreePosition
    flipped: boolean
    gameTree: GameTree
    myColor: Color
    puzzle?: QuestPuzzle['puzzle']
    nextPuzzle?: QuestPuzzle
    currentPuzzleNumber: number
    dayProgress: DayProgress
    weekProgress: QuestPuzzle['week']
    userPuzzleRating: number
    // local state
    moveStatus?: boolean
    hint?: Square
    hintsCount: number
    showSolution: boolean
    puzzleState: boolean | null
    autoMoveTimeout?: NodeJS.Timeout

    // Actions
    resetGame: Action<PuzzleQuestModel>
    savePuzzle: Action<PuzzleQuestModel, QuestPuzzle>
    setCurrentPosition: Action<PuzzleQuestModel, GameTreePosition>
    flipBoard: Action<PuzzleQuestModel>
    setNextPuzzle: Action<PuzzleQuestModel, QuestPuzzle>
    setDayProgress: Action<PuzzleQuestModel, QuestPuzzle['day']>
    setWeekProgress: Action<PuzzleQuestModel, QuestPuzzle['week']>
    // Local Actions
    setMoveStatus: Action<PuzzleQuestModel, boolean | undefined>
    setHint: Action<PuzzleQuestModel, Square | undefined>
    setShowSolution: Action<PuzzleQuestModel, boolean>
    setPuzzleState: Action<PuzzleQuestModel, boolean | null>
    setAutoMoveTimeout: Action<PuzzleQuestModel, NodeJS.Timeout>

    autoMove: Thunk<PuzzleQuestModel>
    handleMove: Thunk<PuzzleQuestModel, Move>
    asyncSavePuzzle: Thunk<PuzzleQuestModel, QuestPuzzle>

    // API
    getPuzzle: Thunk<PuzzleQuestModel>
    finishPuzzle: Thunk<PuzzleQuestModel, { id: string; passed: boolean }>
}

const initialGameTree = createRootGameTree(BasePositionFEN)
const initialCurrentPosition = getStartingPosition(initialGameTree)

export const PuzzleQuestViewModel: PuzzleQuestModel = {
    dayProgress: {
        completed: false,
        endTime: '',
        progress: [],
        reward: 0,
        isLimitReached: false,
    },
    weekProgress: {
        rewards: [],
        rewardsTotal: 0,
        rewardsThreshold: [0, 0, 0],
        endTime: '',
        season: '',
        stepsToGo: 0,
    },
    puzzle: undefined,
    userPuzzleRating: 0,
    moveStatus: false,
    hint: undefined,
    hintsCount: 0,
    showSolution: false,
    puzzleState: null,
    currentPuzzleNumber: 1,
    nextPuzzle: undefined,
    puzzleId: '',
    autoMoveTimeout: undefined,
    myColor: Color.White,
    colorToMove: Color.White,
    flipped: false,
    gameTree: initialGameTree,
    currentPosition: initialCurrentPosition,

    // ACTIONS
    setMoveStatus: action((state, payload) => {
        state.moveStatus = payload
    }),
    setHint: action((state, payload) => {
        state.hintsCount = state.hintsCount + 1
        state.hint = payload
    }),
    setShowSolution: action((state, payload) => {
        state.showSolution = payload
    }),
    setPuzzleState: action((state, payload) => {
        state.puzzleState = payload
    }),

    resetGame: action((state) => {
        state.myColor = Color.White
        state.gameTree = initialGameTree
        state.currentPosition = initialCurrentPosition
        state.colorToMove = 1
    }),

    flipBoard: action((state) => {
        state.flipped = !state.flipped
    }),

    savePuzzle: action((state, payload) => {
        if (!payload.puzzle || payload.puzzle.id === state.puzzleId) return
        const puzzlePGNstring = createPuzzlePGNString(payload.puzzle.startingFen, payload.puzzle?.moves)
        const gameTreeFromPGN = gameFromPGN(puzzlePGNstring)
        const startingPosition = getStartingPosition(gameTreeFromPGN)

        clearTimeout(state.autoMoveTimeout)
        state.hintsCount = 0
        state.puzzleState = null
        state.hint = undefined
        state.moveStatus = undefined
        state.showSolution = false

        // if it's the first move, set the color to move to the color of the starting position
        if (payload.puzzle?.isFirstMove) {
            state.myColor = startingPosition.position.turn
            state.flipped = startingPosition.position.turn === Color.Black
        } else {
            const color = startingPosition.position.turn === Color.White ? Color.Black : Color.White
            state.myColor = color
            state.flipped = color === Color.Black
        }

        state.puzzle = payload.puzzle
        state.weekProgress = payload.week
        payload.day && (state.currentPuzzleNumber = payload.day.progress.length + 1)
        state.nextPuzzle = undefined
        state.puzzleId = payload.puzzle?.id || ''
        state.userPuzzleRating = payload.userPuzzleRating

        if (payload.day?.completed) {
            state.gameTree = initialGameTree
            state.currentPosition = getStartingPosition(initialGameTree)
        } else {
            state.gameTree = gameTreeFromPGN
            state.currentPosition = startingPosition
        }

        payload.day && (state.dayProgress = payload.day)
    }),

    setCurrentPosition: action((state, payload) => {
        state.currentPosition = payload
    }),

    setNextPuzzle: action((state, payload) => {
        state.nextPuzzle = payload
    }),

    setDayProgress: action((state, payload) => {
        payload && (state.dayProgress = payload)
    }),
    setWeekProgress: action((state, payload) => {
        payload && (state.weekProgress = payload)
    }),
    setAutoMoveTimeout: action((state, payload) => {
        state.autoMoveTimeout = payload
    }),

    autoMove: thunk((actions, payload, { getState }) => {
        const state = getState()

        const moveIndex = state.gameTree.moves.findIndex((move) => move.previousPositionId === state.currentPosition.id)
        const timeout = setTimeout(
            () => {
                actions.setMoveStatus(undefined)
                const nextPosition = getNextPosition(state.gameTree, state.currentPosition.id)
                if (nextPosition) {
                    actions.setCurrentPosition(nextPosition)
                } else {
                    actions.finishPuzzle({ id: state.puzzleId, passed: false })
                    actions.setPuzzleState(false)
                }
            },
            moveIndex === 0 ? autoMoveTimeout * 2 : autoMoveTimeout,
        )

        actions.setAutoMoveTimeout(timeout)
    }),

    handleMove: thunk((actions, move, { getState }) => {
        const state = getState()
        actions.setHint(undefined)
        // if (gameTreePosition.position.turn !== myColor) return false
        const currentProgressPosition = getPositionById(state.gameTree, state.currentPosition.id)
        const expectedProgressMove = getMoveById(state.gameTree, currentProgressPosition?.nextMoveIds[0])
        if (!expectedProgressMove) return false
        const { from: expectedFrom, to: expectedTo } = expectedProgressMove.move
        const { from, to } = move

        if (from === expectedFrom && to === expectedTo) {
            actions.setMoveStatus(true)

            const nextPosition = getNextPosition(state.gameTree, state.currentPosition.id)
            nextPosition && actions.setCurrentPosition(nextPosition)

            const nextBotPosition = getPositionById(state.gameTree, expectedProgressMove.nextPositionId)
            if (nextBotPosition.nextMoveIds.length === 0) {
                actions.setPuzzleState(true)
                actions.finishPuzzle({ id: state.puzzleId, passed: true })
            }
            return true
        } else {
            actions.setMoveStatus(false)
            actions.setPuzzleState(false)

            const nextPosition = addMoveToGTPosition(state.gameTree, state.currentPosition, move)
            actions.setCurrentPosition(nextPosition)
            actions.finishPuzzle({ id: state.puzzleId, passed: false })

            return false
        }
    }),

    asyncSavePuzzle: thunk(async (actions, payload, helpers) => {
        clearTimeout(helpers.getState().autoMoveTimeout)

        if (payload?.day?.completed) {
            // ANALYTICS
            const reward = getReward(payload?.week)
            const prevReward = getReward(helpers.getState().weekProgress)
            analyticsManager.dispatchEvent('reward', {
                type: 'crown',
                tier: 0,
                amount: payload?.day?.reward || 0,
                expirationDate: payload?.week?.endTime || '',
            })

            if (reward && reward !== prevReward) {
                analyticsManager.dispatchEvent('reward', {
                    type: 'frame',
                    tier: reward,
                    amount: 1,
                    expirationDate: payload?.week?.endTime || '',
                })
            }
            // ############

            actions.setDayProgress({ ...payload.day, completed: false })
            setTimeout(() => {
                actions.setDayProgress(payload.day)
            }, 2000)
            setTimeout(() => {
                actions.setWeekProgress(payload.week)
            }, 3000)
        } else {
            actions.setDayProgress(payload.day)
        }
    }),

    // API
    getPuzzle: thunk(async (actions) => {
        const data = await getPuzzleQuest()
        if (!data) return
        actions.savePuzzle(data)
    }),

    finishPuzzle: thunk(async (actions, payload, helpers) => {
        const data = await resolvePuzzle(payload.id, payload.passed)
        if (!data) {
            actions.getPuzzle()
            console.error('Failed to resolve puzzle')
            return
        }
        // ANALYTICS
        const currentState = helpers.getState()
        analyticsManager.dispatchEvent('completedPuzzle', {
            origin: 'puzzleQuest',
            result: currentState.showSolution
                ? PuzzleStatus.SHOWED
                : payload.passed
                  ? PuzzleStatus.PASSED
                  : PuzzleStatus.FAILED,
            attempts: 1,
            usedHints: currentState.hintsCount,
            puzzleRating: currentState.puzzle?.rating || 0,
            userPuzzleRating: currentState.userPuzzleRating,
            datePlayed: new Date().toISOString(),
            puzzleId: payload.id,
            puzzleSource: 0,
        })
        // #########
        actions.setNextPuzzle(data)
        actions.asyncSavePuzzle(data)
    }),
}
