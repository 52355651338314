import { useStoreState } from '../../../store/hooks'
import { NotationHeader } from '../../PuzzlesView/components/v2/NotationHeader'

export const StatusHeader = () => {
    const dayProgress = useStoreState((state) => state.puzzleQuestView.dayProgress)
    const hint = useStoreState((state) => state.puzzleQuestView.hint)
    const moveStatus = useStoreState((state) => state.puzzleQuestView.moveStatus)
    const myColor = useStoreState((state) => state.puzzleQuestView.myColor)
    const puzzleState = useStoreState((state) => state.puzzleQuestView.puzzleState)
    const showSolution = useStoreState((state) => state.puzzleQuestView.showSolution)

    return (
        <NotationHeader
            myColor={myColor}
            usedShowSolution={showSolution}
            hint={Boolean(hint)}
            usedHint={false}
            usedAttempts={false}
            isBotMoving={false}
            puzzleState={puzzleState}
            isPuzzleStarted={Boolean(dayProgress.endTime)}
            isLastMoveCorrect={Boolean(moveStatus)}
            mode="puzzleQuest"
        />
    )
}
