import Stack from '@mui/material/Stack'
import { useEffect, useState } from 'react'
import { AreaType, UserData } from '../GlobalHeader'
import AnalysisIcon from '../icons/analysis'
import BurgerIcon from '../icons/burger'
import DailyPuzzleIcon from '../icons/daily_puzzle'
import HomeIcon from '../icons/home'
import LeaderboardIcon from '../icons/leaderboard'
import LiveGamesIcon from '../icons/live_games'
import MembershipsIcon from '../icons/memberships'
import NewsIcon from '../icons/news'
import PlayIcon from '../icons/play'
import PuzzleIcon from '../icons/puzzle'
import { PuzzleQuestIcon } from '../icons/puzzle-quest'
import ShopIcon from '../icons/shop'
import VideosIcon from '../icons/videos'
import { IAppConfig, getAppConfig } from '../services/AppConfigService'
import { colors } from '../theme/colors'
import Dropdown, { DropdownMenuItem } from './Dropdown'

export type BurgerNavigationProps = {
    websiteURL: string
    playAreaURL: string
    forumsURL: string
    learningURL: string
    shopURL: string
    area: AreaType
    Link: any
    user?: UserData | null
}

const BurgerNavigation = (props: BurgerNavigationProps) => {
    const { websiteURL, playAreaURL, forumsURL, learningURL, shopURL, area, Link, user } = props
    const [appConfig, setAppConfig] = useState<IAppConfig | null>(null)

    useEffect(() => {
        const config = getAppConfig()
        setAppConfig(config)
    }, [])

    let burgerDropdownItems: DropdownMenuItem[] = [
        {
            link: `${websiteURL}/`,
            title: 'Home',
            icon: <HomeIcon />,
        },
        {
            link: `${playAreaURL}/`,
            title: 'Play now',
            // target: '_blank',
            icon: <PlayIcon />,
        },
        // TODO: Uncomment when learning is ready
        // {
        //     id: 2,
        //     link: learningURL,
        //     title: 'Learn',
        //     icon: '',
        // },
        {
            link: `${playAreaURL}/watch`,
            title: 'Watch Games',
            icon: <LiveGamesIcon />,
        },
        {
            link: `${playAreaURL}/analysis`,
            title: 'Analysis',
            icon: <AnalysisIcon />,
        },
        {
            link: `${playAreaURL}/puzzle-quest`,
            // title: <>Puzzle Quest {user && !user.ftue.puzzleQuest ? <span>new</span> : ''}</>,
            title: (
                <>
                    Puzzle Quest <span>new</span>
                </>
            ),
            icon: <PuzzleQuestIcon />,
            // new: user && !user.ftue.puzzleQuest,
            new: true,
        },

        {
            link: `${playAreaURL}/puzzles`,
            title: 'Puzzles',
            icon: <PuzzleIcon />,
        },
        {
            link: `${playAreaURL}/daily-puzzle`,
            title: 'Daily puzzle',
            icon: <DailyPuzzleIcon />,
        },
        {
            link: `${websiteURL}/leaderboard`,
            title: 'Leaderboard',
            icon: <LeaderboardIcon />,
        },
        {
            link: `${websiteURL}/videos`,
            title: 'Videos',
            icon: <VideosIcon />,
        },
        {
            link: `${websiteURL}/news-and-articles`,
            title: 'News',
            icon: <NewsIcon />,
        },
        {
            link: `${shopURL}`,
            title: 'Shop',
            icon: <ShopIcon />,
        },
        // TODO: Uncomment when forums are ready
        // {
        //     link: forumsURL,
        //     title: 'Forums',
        //     icon: '',
        // },
        ...(!!appConfig?.hideMemberships
            ? []
            : [
                  {
                      link: `${websiteURL}/memberships`,
                      title: 'Memberships',
                      icon: <MembershipsIcon />,
                  },
              ]),
    ]

    return (
        <Stack
            className="burger-menu-wrapper"
            direction="row"
            sx={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Dropdown
                Link={Link}
                area={area}
                menuButtonTitle={
                    <Stack
                        direction="row"
                        sx={{
                            // py: '10px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            svg: {
                                zIndex: 2,
                            },
                            '::after': {
                                display: burgerDropdownItems.some((item) => item.new) ? 'block' : 'none',
                                content: "''",
                                zIndex: 1,
                                position: 'absolute',
                                top: '-8px',
                                right: '-8px',
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: colors.lightRed,
                            },
                        }}
                    >
                        <BurgerIcon />
                    </Stack>
                }
                dropdownItems={burgerDropdownItems}
            />
        </Stack>
    )
}
export default BurgerNavigation
