import React from 'react'
import { AreaType } from '../GlobalHeader'

interface IProps {
    href?: string
    target?: string
    rel?: string
    style?: React.CSSProperties
    Link: any
    children: React.ReactNode
    area: AreaType
    className?: string
}

const GlobalLink: React.FC<IProps> = (props) => {
    const { href, target, rel, style, children, Link, area, className } = props

    if (area === AreaType.PLAY_AREA) {
        return (
            <Link className={'header-' + className} to={href} target={target} rel={rel} style={style}>
                {children}
            </Link>
        )
    }

    return (
        <Link className={'header-' + className} href={href} target={target} rel={rel} style={style}>
            {children}
        </Link>
    )
}

export default GlobalLink
