import { Action, action } from 'easy-peasy'
import { positionToFEN } from '../../chess/fen'
import { createRootGameTree, getStartingPosition, undoLastMove } from '../../chess/gameTree'
import { BasePositionFEN } from '../../chess/positionPresets'
import { Color, GameTree } from '../../chess/types'

interface Validations {
    prevDate: boolean
    nextDate: boolean
}

export type DailyPuzzleModel = {
    flipped: boolean
    puzzleGameTree: GameTree
    progressCurrentPositionId: string
    gameTree: GameTree
    currentPositionId: string
    myColor: Color
    colorToMove: number
    userRating: number
    validations: Validations
    setGameTree: Action<DailyPuzzleModel, GameTree>
    setProgressCurrentPositionId: Action<DailyPuzzleModel, string>
    setCurrentPositionId: Action<DailyPuzzleModel, string>
    setFlipBoard: Action<DailyPuzzleModel, boolean>
    initialiseGame: Action<DailyPuzzleModel, { gameTree?: GameTree | undefined; isFirstMove?: boolean | undefined }>
    setColorToMove: Action<DailyPuzzleModel, number>
    undoLastMove: Action<DailyPuzzleModel, GameTree>
    resetGame: Action<DailyPuzzleModel>
    setUserRating: Action<DailyPuzzleModel, number>
    setValidations: Action<DailyPuzzleModel, Validations>
}

const initialGameTree = createRootGameTree(BasePositionFEN)
const initialCurrentPosition = getStartingPosition(initialGameTree).id

const DailyPuzzleViewModel: DailyPuzzleModel = {
    myColor: Color.White,
    flipped: false,
    puzzleGameTree: initialGameTree,
    progressCurrentPositionId: initialCurrentPosition,
    gameTree: initialGameTree,
    currentPositionId: initialCurrentPosition,
    colorToMove: 1,
    userRating: 0,
    validations: { prevDate: true, nextDate: false },

    // ----------------------------------------------------------------------------- actions
    setGameTree: action((state, payload) => {
        state.gameTree = payload
        state.currentPositionId = getStartingPosition(payload).id
    }),

    setProgressCurrentPositionId: action((state, payload) => {
        state.progressCurrentPositionId = payload
    }),

    setCurrentPositionId: action((state, payload) => {
        state.currentPositionId = payload
    }),

    setFlipBoard: action((state, flipped) => {
        state.flipped = flipped
    }),

    initialiseGame: action((state, gd) => {
        const newPuzzleGameTree = gd.gameTree || initialGameTree
        state.puzzleGameTree = newPuzzleGameTree
        const startingProgressPosition = getStartingPosition(newPuzzleGameTree)
        state.progressCurrentPositionId = startingProgressPosition.id

        const newGameTree = createRootGameTree(positionToFEN(startingProgressPosition.position))
        state.gameTree = newGameTree
        state.currentPositionId = getStartingPosition(newGameTree).id

        if (gd.isFirstMove) {
            state.myColor = startingProgressPosition.position.turn
        } else if (startingProgressPosition.position.turn === Color.Black) {
            state.myColor = Color.White
        } else {
            state.myColor = Color.Black
        }

        state.flipped = state.myColor === Color.Black
    }),

    setColorToMove: action((state, number) => {
        state.colorToMove = number
    }),

    undoLastMove: action((state, payload) => {
        const previousPositionId = undoLastMove(payload).id || initialCurrentPosition
        state.currentPositionId = previousPositionId
        state.gameTree = payload
    }),

    resetGame: action((state) => {
        state.myColor = Color.White
        state.puzzleGameTree = initialGameTree
        state.progressCurrentPositionId = initialCurrentPosition
        state.gameTree = initialGameTree
        state.currentPositionId = initialCurrentPosition
        state.colorToMove = 1
    }),

    setUserRating: action((state, rating) => {
        state.userRating = rating
    }),

    setValidations: action((state, validations) => {
        state.validations = validations
    }),
}

export default DailyPuzzleViewModel
