import { Box, Typography } from '@mui/material'
import { useStoreState } from '../../../store/hooks'

export const PuzzleHeader = () => {
    const currentPuzzleNumber = useStoreState((state) => state.puzzleQuestView.currentPuzzleNumber)

    return (
        <Box
            sx={{
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                h5: {
                    position: 'relative',
                    marginLeft: '40px',
                },
                'h5:after': {
                    position: 'absolute',
                    content: '""',
                    left: '-32px',
                    width: '26px',
                    height: '26px',
                    backgroundImage: 'url(assets/images/icons/puzzle-quest-icon.svg)',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                },
            }}
        >
            <Typography variant="h5" color="secondary">
                Puzzle Quest {currentPuzzleNumber <= 10 && `${currentPuzzleNumber}/10`}
            </Typography>
        </Box>
    )
}
