import { useEffect, useMemo } from 'react'
import useSound from 'use-sound'
import AppLayout from '../../components/AppLayout/AppLayout'
import { isGuest } from '../../helpers'
import { LoginState } from '../../sharedComponents/src/globalHeader/GlobalHeader'
import { useResponsiveSizings } from '../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../store/hooks'
import PuzzlesChessBoard from '../chessboard/PuzzlesChessboard'
import PuzzlesLimitDialog from '../PuzzlesView/components/v2/PuzzleLimitDialog'
import useResultSounds from '../PuzzlesView/hooks/useResultSounds'
import { BoardOverlay } from './components/BoardOverlay'
import { RewardPopup } from './components/RewardPopup'
import { DesktopPQ } from './layouts/DesktopPQ'
import { MobilePQ, MobileWeeklyProgress } from './layouts/MobilePQ'
import { autoFeedTimeout } from './PuzzleQuestModel'

export const PuzzleQuest = () => {
    const settings = useStoreState((state) => state.gameView.settings)
    const [playResolve] = useSound(`/assets/sounds/default/Reward.mp3`, { volume: 0.25 })
    const playResultSound = useResultSounds(settings)
    const layout = useResponsiveSizings()

    const currentPosition = useStoreState((state) => state.puzzleQuestView.currentPosition)
    const dayProgress = useStoreState((state) => state.puzzleQuestView.dayProgress)
    const flipped = useStoreState((state) => state.puzzleQuestView.flipped)
    const gameTree = useStoreState((state) => state.puzzleQuestView.gameTree)
    const hint = useStoreState((state) => state.puzzleQuestView.hint)
    const moveStatus = useStoreState((state) => state.puzzleQuestView.moveStatus)
    const myColor = useStoreState((state) => state.puzzleQuestView.myColor)
    const nextPuzzle = useStoreState((state) => state.puzzleQuestView.nextPuzzle)
    const puzzleState = useStoreState((state) => state.puzzleQuestView.puzzleState)
    const showSolution = useStoreState((state) => state.puzzleQuestView.showSolution)
    const userData = useStoreState((state) => state.userData)

    const autoMove = useStoreActions((actions) => actions.puzzleQuestView.autoMove)
    const savePuzzle = useStoreActions((actions) => actions.puzzleQuestView.savePuzzle)
    const getPuzzle = useStoreActions((actions) => actions.puzzleQuestView.getPuzzle)
    const handleMove = useStoreActions((actions) => actions.puzzleQuestView.handleMove)
    const initialCompletedStatus = useMemo(() => dayProgress.completed, [])

    // AUTO MOVE OR SHOW SOLUTION
    useEffect(() => {
        if (puzzleState === null && !dayProgress.completed) {
            if (currentPosition.position.turn !== myColor || showSolution) {
                autoMove()
            }
        }
    }, [currentPosition, myColor, gameTree, showSolution, puzzleState, dayProgress])

    // HANDLE SWITCH TO NEXT PUZZLE WITH 15 SECS DELAY
    useEffect(() => {
        let timeout

        if (nextPuzzle) {
            timeout = setTimeout(() => {
                savePuzzle(nextPuzzle)
            }, autoFeedTimeout) // 15s
        }
        return () => clearTimeout(timeout)
    }, [nextPuzzle])

    // SOUND FOR REWARD
    useEffect(() => {
        if (initialCompletedStatus !== dayProgress.completed) {
            dayProgress.completed && playResolve()
        }
    }, [dayProgress.completed, initialCompletedStatus])

    // SOUND FOR MOVE
    useEffect(() => {
        if (puzzleState !== null) {
            puzzleState ? playResultSound(true) : playResultSound(false)
        }
    }, [puzzleState])

    useEffect(() => {
        // TIMEOUT TO PREVENT DOUBLE FETCH
        const timeout = setTimeout(() => {
            userData.state === LoginState.LOGGED_IN && getPuzzle()
        }, 1)
        return () => {
            clearTimeout(timeout)
            nextPuzzle && savePuzzle(nextPuzzle)
        }
    }, [userData.state, userData.userData?.user_name])

    if (!dayProgress.endTime && userData.state === LoginState.LOGGED_IN) return null

    const hideBoardOnMobile = dayProgress.completed && layout.resolution.isMobile

    return (
        <AppLayout
            compactChild={!hideBoardOnMobile}
            boardHidden={hideBoardOnMobile}
            aboveBoard={layout.resolution.isMobile && !hideBoardOnMobile ? <MobileWeeklyProgress /> : <> </>}
            board={
                <>
                    <PuzzlesChessBoard
                        mode="puzzleQuest"
                        gameTree={gameTree}
                        currentPositionId={currentPosition.id}
                        flipped={flipped}
                        onMove={handleMove}
                        myColor={myColor}
                        hint={hint}
                        disableBoard={currentPosition.position.turn !== myColor || showSolution}
                        moveStatus={moveStatus}
                    />
                    {!dayProgress.completed && userData.state === LoginState.LOGGED_IN && <BoardOverlay />}
                </>
            }
        >
            {layout.resolution.isDesktop ? <DesktopPQ /> : <MobilePQ />}
            <RewardPopup />
            <PuzzlesLimitDialog
                variant={isGuest(userData.userData?.groups) ? 'puzzleQuestMember' : 'puzzleQuestPremium'}
                open={dayProgress.isLimitReached}
            />
        </AppLayout>
    )
}
