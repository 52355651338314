export const PuzzleQuestIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.8095 10.4762H19.381C20.0756 10.4762 20.7417 10.7521 21.2329 11.2433C21.7241 11.7345 22 12.4006 22 13.0952C22 13.7899 21.7241 14.456 21.2329 14.9472C20.7417 15.4384 20.0756 15.7143 19.381 15.7143H17.8095V19.9048C17.8095 20.4605 17.5888 20.9934 17.1958 21.3863C16.8029 21.7793 16.27 22 15.7143 22H11.7333V20.4286C11.7333 18.8571 10.4762 17.6 8.90476 17.6C7.33333 17.6 6.07619 18.8571 6.07619 20.4286V22H2.09524C1.53955 22 1.00661 21.7793 0.613681 21.3863C0.220748 20.9934 0 20.4605 0 19.9048V15.9238H1.57143C3.14286 15.9238 4.4 14.6667 4.4 13.0952C4.4 11.5238 3.14286 10.2667 1.57143 10.2667H0V6.28571C0 5.73002 0.220748 5.19709 0.613681 4.80416C1.00661 4.41122 1.53955 4.19048 2.09524 4.19048H6.28571V2.61905C6.28571 1.92443 6.56165 1.25827 7.05282 0.767101C7.54398 0.275935 8.21015 0 8.90476 0C9.59938 0 10.2655 0.275935 10.7567 0.767101C11.2479 1.25827 11.5238 1.92443 11.5238 2.61905V4.19048H15.7143C16.27 4.19048 16.8029 4.41122 17.1958 4.80416C17.5888 5.19709 17.8095 5.73002 17.8095 6.28571V10.4762ZM5 9.66667L5.64286 15H13.3571L14 9.66667L11.4286 11.3333L9.5 8L7.57143 11.3333L5 9.66667Z"
            fill="white"
        />
    </svg>
)
