const DailyPuzzleIcon: React.FC = () => (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.8095 10.4762L16 10.5C12.5324 10.5 10.2424 12.4562 10.2424 15.9238C10.2424 16.28 10.2424 17.9303 10.2424 17.9303C9.8309 17.7108 9.37116 17.5973 8.90476 17.6C7.33333 17.6 6.07619 18.8571 6.07619 20.4286V22H2.09524C1.53955 22 1.00661 21.7793 0.613681 21.3863C0.220748 20.9934 0 20.4605 0 19.9048V15.9238H1.57143C3.14286 15.9238 4.4 14.6667 4.4 13.0952C4.4 11.5238 3.14286 10.2667 1.57143 10.2667H0V6.28571C0 5.13333 0.942857 4.19048 2.09524 4.19048H6.28571V2.61905C6.28571 1.92443 6.56165 1.25827 7.05282 0.767101C7.54398 0.275935 8.21015 0 8.90476 0C9.59938 0 10.2655 0.275935 10.7567 0.767101C11.2479 1.25827 11.5238 1.92443 11.5238 2.61905V4.19048H15.7143C16.27 4.19048 16.8029 4.41122 17.1958 4.80416C17.5888 5.19709 17.8095 5.73002 17.8095 6.28571V10.4762Z"
            fill="white"
        />
        <g clipPath="url(#clip0_242_80597)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.4388 12.459C15.6919 12.459 15.8971 12.6642 15.8971 12.9173V13.3757H19.1055V12.9173C19.1055 12.6642 19.3107 12.459 19.5638 12.459C19.8169 12.459 20.0221 12.6642 20.0221 12.9173V13.387C21.1802 13.5019 22.0846 14.479 22.0846 15.6673V19.7923C22.0846 21.058 21.0586 22.084 19.793 22.084H15.2096C13.944 22.084 12.918 21.058 12.918 19.7923V15.6673C12.918 14.479 13.8224 13.5019 14.9805 13.387V12.9173C14.9805 12.6642 15.1857 12.459 15.4388 12.459ZM13.9129 15.209H21.0897C20.901 14.6749 20.3917 14.2923 19.793 14.2923H15.2096C14.611 14.2923 14.1016 14.6749 13.9129 15.209ZM21.168 16.1257H13.8346V19.7923C13.8346 20.5517 14.4502 21.1673 15.2096 21.1673H19.793C20.5524 21.1673 21.168 20.5517 21.168 19.7923V16.1257Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_242_80597">
                <rect width="11" height="11" fill="white" transform="translate(12 12)" />
            </clipPath>
        </defs>
    </svg>
)

DailyPuzzleIcon.displayName = 'DailyPuzzleIcon'

export default DailyPuzzleIcon
