import { format, isValid, parseISO } from "date-fns";

const getParsedDate = (date?: string): Date => {
    let parsedDate = parseISO(format(new Date(), 'yyyy-MM-dd'))
    if (date && isValid(parseISO(date))) {
        parsedDate = parseISO(date)
    }

    return parsedDate
}

export default getParsedDate