import GetCookie from '../../sharedComponents/src/globalHeader/common/getCookie'
import { QuestPuzzle } from './types'

export const getPuzzleQuest = async (): Promise<QuestPuzzle> => {
    return await fetch(`${import.meta.env.VITE_REACT_APP_PUZZLE_URL}/api/quest/puzzles/next`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + GetCookie('jwt'),
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                try {
                    if (response.status === 500) {
                        throw new Error('Internal Server Error')
                    }
                    response.json().then((data) => {
                        console.error(data.message)
                    })
                } catch (error) {
                    throw new Error('Failed to parse JSON')
                }
            }
        })
        .then((data) => data)
        .catch((error) => {
            console.error('[] Error:', error)
        })
}

export const resolvePuzzle = async (id: string, passed: boolean): Promise<QuestPuzzle> => {
    return await fetch(`${import.meta.env.VITE_REACT_APP_PUZZLE_URL}/api/quest/puzzles/${id}/resolve`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + GetCookie('jwt'),
        },
        body: JSON.stringify({ passed }),
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            }
            // else {
            //     try {
            //         response.json().then((data) => {
            //             throw new Error(data)
            //         })
            //     } catch (error) {
            //         throw new Error('Failed to parse JSON')
            //     }
            // }
        })
        .then((data) => data)
        .catch((error) => {
            console.error('[] Error:', error)
        })
}
