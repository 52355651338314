import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { IconButton, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import { format } from 'date-fns'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CalendarIcon from '../../../assets/icons/calendar.svg?react'
import { useStoreState } from '../../../store/hooks'
import getParsedDate from '../helpers/getParsedDate'
import navigateToDate from '../helpers/navigateToDate'

interface IProps {
    disabled: boolean
}

const DailyPuzzleDate: React.FC<IProps> = (props) => {
    const { disabled } = props
    const { date } = useParams<{ date?: string }>()
    const validations = useStoreState((state) => state.dailyPuzzleView.validations)
    const navigate = useNavigate()

    const handleDateChange = (isNext: boolean) => {
        const parsedDate = getParsedDate(date)
        navigateToDate(navigate, new Date(parsedDate), isNext)
    }

    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <IconButton
                size="medium"
                onClick={() => handleDateChange(false)}
                disabled={!validations.prevDate || disabled}
            >
                <ChevronLeftIcon />
            </IconButton>
            <Stack direction="row" gap={1} alignItems="center">
                <CalendarIcon />
                <Typography>{format(getParsedDate(date), 'MMMM dd, yyyy')}</Typography>
            </Stack>
            <IconButton
                size="medium"
                onClick={() => handleDateChange(true)}
                disabled={!validations.nextDate || disabled}
            >
                <ChevronRightIcon />
            </IconButton>
        </Stack>
    )
}

DailyPuzzleDate.displayName = 'DailyPuzzleDate'

export default DailyPuzzleDate
