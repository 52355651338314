import { Color, Square } from '../chess/types'
import { ColorSelection } from '../components/colorSelector/ColorSelector'
import { RatingType } from '../components/matchmaking/ChallengeCard'
import { MM_MESSAGES, MM_REQUESTS } from '../features/matchMakingDrawer/matchMakingConnector/MMTypes'

export enum HighlightType {
    ACTIVE_PIECE, // this is a piece I have selected
    LEGAL_MOVE_TARGET, // this is a square the selected piece can move to (has a mouseover)
    MANUAL_HIGHLIGHT, // I have highlighted this square (circle)
    CHECK, // this is a special highlight for the king if it is in check
    LAST_MOVE, // this is a highlight for the last made legal move
    PREMOVE, // this is the source and target square of a premove
}

export type SquareHighlight = {
    type: HighlightType
    square: Square
}

export type Arrow = {
    from: Square
    to: Square
}

export enum ConnectionStatus {
    NOT_INITIALIZED = 'notInitialized',
    CONNECTING = 'connecting',
    CONNECTED = 'connected',
    CONNECTION_ERROR = 'connectionError',
    CONNECTION_CLOSED = 'connectionClosed',
    RECONNECT_FAILED = 'reconnectFailed',
}

export type RatingObject = {
    rating: number
    win: number
    draw: number
    loss: number
    game_type: RatingType
    total_games: number
    is_provisional: boolean
}

export type RatingsObject = {
    blitz: RatingObject
    bullet: RatingObject
    rapid: RatingObject
    classic: RatingObject
}

export type Player = {
    id: string
    name: string
    avatarUrl: string
    nationality: string
    rating: number
    title?: Title
    userName?: string
}

export type SearchOptions = {
    customChallenge: {
        minutes: number
        increment: number
        rated: boolean
        color: ColorSelection
        fullRatingRange: boolean
    }
    computerChallenge: {
        selectedBot?: Bot
        selectedChallenge: string | null
        color: ColorSelection
        strength: number
        customTime: boolean
        minutes: number
        increment: number
    }
}

export enum Title {
    Grandmaster = 'GM',
    InternationalMaster = 'IM',
    FideMaster = 'FM',
    CandidateMaster = 'CM',
    WomanGrandmaster = 'WGM',
    WomanInternationalMaster = 'WIM',
    WomanFideMaster = 'WFM',
    WomanCandidateMaster = 'WCM',
}

export enum GameResultType {
    Checkmated = 'checkmated',
    ForfeitedOnTime = 'forfeited on time',
    Resigned = 'resigned',
    Disconnected = 'disconnected',
    Aborted = 'aborted',
    Draw = 'draw',
    Unknown = 'unknown',
    InsufficientMaterial = 'insufficient material',
    Stalemate = 'stalemate',
    ThreefoldRepetition = 'threefold repetition',
    FivefoldRepetition = 'fivefold repetition',
    FiftyMoveRule = 'fifty move rule',
    SeventyFiveMoveRule = 'seventy five move rule',
    TechnicalErrorAi = 'technical error ai',
    NoReason = 'no reason',
    Timeout = 'timeout',
    SecondPlayerTimeout = 'opponent failed to connect',
    NoPlayerMoveSinceStart = 'did not move',
    AllPlayersDisconnected = 'both players disconnected',
    PlayerDisconnected = 'disconnected and forfeits',
}

export type GameResult = {
    type: GameResultType
    winner: Color | undefined
    newRating?: {
        player1_id: string
        user1_rating: number
        player2_id: string
        user2_rating: number
    }
    clock?: ClockData
    outcome?: string
}

export enum ChallengeType {
    Blitz = 'Blitz',
    Bullet = 'Bullet',
    Rapid = 'Rapid',
}

export type TimeMode = {
    durationMinutes: number
    clockIncrementSeconds: number
    name: RatingType
}

export type ClockData = {
    whiteLeft: number
    blackLeft: number
    runningFor: 'white' | 'black'
    timeStamp: number // this would be better called lastMoveTimestamp
    moveDuration: number
}

export enum MMChallengeType {
    AUTO = 'autoMatch',
    DIRECT = 'direct',
    CUSTOM = 'custom',
}

export enum ChallengeState {
    ITEM_UNSPECIFIED,
    ITEM_ADDED,
    ITEM_REMOVED,
}

// the remaining time on a clock at a given instant. Can be used to calculate how much time remains later
// with the function getClockInstantCurrentRemaining
/**
 * ClockInstant
 * @property secondsRemaining - the number of seconds remaining when the clock was started
 * @property when - the instant at which the clock was started (in other words - the timestamp of the other player's move)
 */
export type ClockInstant = {
    secondsRemaining: number
    when: number
}

export type ChallengeEntry = {
    recordState?: number
    challengeId: string
    pairId?: string
    challengeType: MMChallengeType
    rated: boolean
    challengerColor: string
    timeMode: TimeMode
    challenger: {
        username: string
        rating: number
        is_provisional: boolean
        nationality: string
        title?: string
        avatarUrl?: string
        reward: {
            tier: number
            season: string
        }
    }
    ratingRange?: { from: number; to: number }
    challengee?: {
        username: string
        rating: number
        is_provisional: boolean
        nationality: string
        title?: string
        avatarUrl?: string
    }
    FEN?: string
}

export type PGNHeader = {
    Event: string
    Site: string
    Date: string
    Round: string
    White: string
    Black: string
    Result: string
    WhiteElo?: string
    BlackElo?: string
    Opening?: string
    WhiteTitle?: string
    BlackTitle?: string
    WhiteUSCF?: string
    BlackUSCF?: string
    WhiteNA?: string
    BlackNA?: string
    WhiteType?: string
    BlackType?: string
    EventDate?: string
    EventSponsor?: string
    Section?: string
    Stage?: string
    Board?: string
    Variation?: string
    SubVariation?: string
    ECO?: string
    NIC?: string
    Time?: string
    UTCTime?: string
    UTCDate?: string
    TimeControl?: string
    SetUp?: string
    FEN?: string
    Termination?: string
    Annotator?: string
    Mode?: string
    PlyCount?: string
    // custom fields:
    BlackAvatarUrl?: string
    WhiteAvatarUrl?: string
    BlackCountry?: string
    WhiteCountry?: string
    WhiteClock?: string
    BlackClock?: string
    TimeMode?: string
}

// ======================================================================= Gameserver API types

export type Result = {
    pairId: string
    matchKind: MatchKind
    timeMode: TimeMode
    player1: PlayerCompressed
    player2: PlayerCompressed
    startingFEN?: string
}

export type PlayerCompressed = {
    id: string
    color: 'white' | 'black'
    playerKind: string
    username: string
    country: string
    title: string
    avatarUrl: string
    reward: {
        tier: number
        season: string
    }
}

export type MatchData = {
    gameServiceUrl: string
    matchId: string
    messageType?: MM_MESSAGES
    result?: Result
}

export type PlayerInGameData = {
    playerKind: 'machine' | 'human'
    playerId: string
    playerColor: 'white' | 'black'
    ratings: RatingsObject
    firstName: string
    lastName: string
    countryId: string
    title: string
    avatarUrl: string
    userName: string
    provisionalRating: boolean
    reward: {
        tier: number
        season: string
    }
}

export type MatchKind = 'human_vs_ai' | 'human_vs_human'

export type GameData = {
    player1: PlayerInGameData
    player2: PlayerInGameData
    matchId: string
    matchKind: MatchKind
    timeMode: TimeMode
    startingFen?: string
    difficulty?: { level: number }
    rated: boolean
    timeStamp: number
}

export type GameDataOnReconnect = {
    player1: PlayerInGameData
    player2: PlayerInGameData
    matchId: string
    matchKind: MatchKind
    state: string // this should be an enum
    timeMode: TimeMode
    rated: boolean
    public: boolean
    pgn: string
    difficulty?: { level: number }
    createdAt: string
    clock: ClockData
}

export type LiveGameData = {
    clock: ClockData
    createdAt: string
    matchId: string
    matchKind: MatchKind
    player1: PlayerInGameData
    player2: PlayerInGameData
    state: string
    timeMode: TimeMode
    rated: boolean
    difficulty: {
        level: number
    }
    startingFen: string
    pgn: string
}

// ======================================================================= Matchmaker API types

export type AIChallengeCreate = {
    requestType: MM_REQUESTS.CHALLENGE_CREATE
    rawData: {
        challengerColor: 'white' | 'black' | 'no_color'
        FEN?: string
        timeMode: {
            durationMinutes: number
            clockIncrementSeconds: number
        }
        matchKind: 'human_vs_ai'
        difficulty: {
            level: number
        }
        challengeeId?: string
    }
    correlationId?: string
}

export interface ScoreboardEntry {
    pairId: string
    challenger: {
        playerId: string
        score: Array<number>
    }
    challengee: {
        playerId: string
        score: Array<number>
    }
}

export type Bot = {
    id: string
    name: string
    bio?: string
    startingFEN?: string
    difficultyLevel: number
    strengthLevel?: string
    strengthRating: string
    avatarURL: string
    defaultColor: ColorSelection
    timeMode?: TimeMode
}
