import { Box, LinearProgress } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { getPuzzleQuestAsset } from '../../../sharedComponents/src/helpers/getPuzzleQuestAsset'
import { useStoreState } from '../../../store/hooks'

const rewardSize = 36
const zoomLevel = 1.6

export const Reward: FC<{ active: boolean; tier: number; season: string }> = ({ active, tier, season }) => {
    useEffect(() => {
        const svgImg = document.getElementById(`reward-${tier}`) as any
        if (!svgImg) return
        const src = svgImg?.src
        svgImg.src = '' // Temporarily remove the src
        svgImg.src = src + '?' + new Date().getTime() // Reload with a cache
    }, [active])

    return (
        <Box
            sx={{
                height: `${rewardSize * zoomLevel}px`,
                width: `${rewardSize}px`,
                position: 'relative',
                img: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    filter: 'drop-shadow(3px 3px 0 rgba(0, 0, 0, 0.25))',

                    [`&#reward-${tier}`]: {
                        '@keyframes pulse': {
                            '0%': {
                                transform: 'scale(1)',
                            },
                            '75%': {
                                transform: `scale(${zoomLevel})`,
                            },
                            '90%': {
                                transform: `scale(${zoomLevel - 0.1})`,
                            },
                            '100%': {
                                transform: `scale(${zoomLevel - 0.3})`,
                            },
                        },
                        animation: '0.5s pulse linear forwards',
                        animationDelay: '1s',
                    },
                },
            }}
        >
            <img height="100%" width="100%" src={getPuzzleQuestAsset(season, tier, 'disabled')} />
            {active && (
                <img
                    id={`reward-${tier}`}
                    height="100%"
                    width="100%"
                    src={getPuzzleQuestAsset(season, tier, 'active')}
                />
            )}
        </Box>
    )
}

// MIN = Minimum expected value
// MAX = Maximum expected value
// Function to normalise the values (MIN / MAX could be integrated)
const normalise = (value, threshold) => {
    const limit = threshold[threshold.length - 1]
    const normalisedValue = (value * 100) / limit

    return Math.min(100, normalisedValue)
}

export const WeeklyProgress = () => {
    const weekProgress = useStoreState((state) => state.puzzleQuestView.weekProgress)
    const dayCompleted = useStoreState((state) => state.puzzleQuestView.dayProgress.completed)

    const [count, setCount] = useState<number>(
        dayCompleted && weekProgress?.rewardsTotal
            ? weekProgress.rewardsTotal - (weekProgress.rewards[weekProgress.rewards.length - 1] || 0)
            : 0,
    ) // fullfill the progress bar with initial data

    const [isInit, setIsInit] = useState(dayCompleted)

    useEffect(() => {
        let timeout

        if (weekProgress?.rewardsTotal) {
            const crownsCollected = weekProgress.rewardsTotal

            const modifiedThreshold = [1].concat(weekProgress.rewardsThreshold)

            !isInit &&
                modifiedThreshold.forEach((threshold, index) => {
                    timeout = setTimeout(() => {
                        if (crownsCollected > threshold) {
                            setCount(threshold)
                        } else {
                            setCount(crownsCollected)
                            clearTimeout(timeout)
                            setIsInit(true)
                        }
                    }, 1000 * index)
                })

            if (isInit) {
                const newMiddleValue = modifiedThreshold.findLast((threshold) => crownsCollected >= threshold)
                newMiddleValue && setCount(newMiddleValue)

                setTimeout(() => setCount(crownsCollected), newMiddleValue ? 1000 : 1)
            }
        } else {
            setCount(0)
        }
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [weekProgress?.rewardsTotal])

    if (!weekProgress) return null

    return (
        <Box
            display="flex"
            flexDirection="column"
            position="relative"
            justifyContent="center"
            width={{ xs: '100%', md: 'auto' }}
            height={`${rewardSize * zoomLevel}px`}
            mx={1}
        >
            <LinearProgress
                color="secondary"
                sx={{
                    height: '6px',
                    mx: `${rewardSize / 2}px`,
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: `${(rewardSize * zoomLevel - 6) / 2}px`,
                    '.MuiLinearProgress-bar': {
                        borderRadius: '3px',
                    },
                    '&.MuiLinearProgress-colorSecondary': {
                        backgroundColor: '#585D63',
                        boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.25)',
                    },
                    img: {
                        backgroundColor: 'red',
                        filter: 'drop-shadow(3px 3px 0px 0px rgba(0, 0, 0, 0.25))',
                    },
                }}
                variant="determinate"
                value={normalise(count, weekProgress?.rewardsThreshold)}
            />
            <Box display="flex" justifyContent="space-between">
                <Reward season={weekProgress.season} active={Boolean(count)} tier={0} />
                <Reward season={weekProgress.season} active={count >= weekProgress.rewardsThreshold[0]} tier={1} />
                <Reward season={weekProgress.season} active={count >= weekProgress.rewardsThreshold[1]} tier={2} />
                <Reward season={weekProgress.season} active={count >= weekProgress.rewardsThreshold[2]} tier={3} />
            </Box>
        </Box>
    )
}
