import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useStoreState } from '../../../store/hooks'
import { autoMoveTimeout } from '../PuzzleQuestModel'

export const BoardOverlay = () => {
    const [showOverlay, setShowOverlay] = useState(true)

    const currentPuzzleNumber = useStoreState((state) => state.puzzleQuestView.currentPuzzleNumber)

    useEffect(() => {
        setShowOverlay(true)
        setTimeout(() => {
            setShowOverlay(false)
        }, autoMoveTimeout * 2)
    }, [currentPuzzleNumber])

    if (!showOverlay) return null

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                '.topOverlay': {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '50%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust overlay color and opacity
                    animation: `moveTopToCenter ${autoMoveTimeout * 2}ms ease forwards`,
                },
                '.bottomOverlay': {
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    height: '50%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust overlay color and opacity
                    animation: `moveBottomToCenter ${autoMoveTimeout * 2}ms ease forwards`,
                },
                '.text': {
                    textShadow: '1px 1px 2px #000',
                    position: 'absolute',
                    zIndex: 1,
                    // opacity: '1 !important',
                    opacity: '0',
                    width: '100%',
                    left: 0,
                    right: 0,
                    textAlign: 'center',
                    transform: 'scale(0.6)',
                    animation: `fadeInOutText ${autoMoveTimeout * 2}ms ease forwards`,
                },
                '@keyframes moveTopToCenter': {
                    '0%': {
                        transform: 'translateY(-100%)',
                    },
                    '50%': {
                        transform: 'translateY(0)',
                    },
                    '100%': {
                        transform: 'translateY(-100%)',
                    },
                },
                '@keyframes moveBottomToCenter': {
                    '0%': {
                        transform: 'translateY(100%)',
                    },
                    '50%': {
                        transform: 'translateY(0)',
                    },
                    '100%': {
                        transform: 'translateY(100%)',
                    },
                },
                '@keyframes fadeInOutText': {
                    '0%': {
                        opacity: 0,
                        transform: 'scale(0.6)',
                    },
                    '40%': {
                        opacity: 1,
                        transform: 'scale(1)',
                    },
                    '60%': {
                        opacity: 1,
                        transform: 'scale(1)',
                    },
                    '100%': {
                        opacity: 0,
                        transform: 'scale(0.6)',
                    },
                },
            }}
        >
            <div className="topOverlay"></div>
            <Typography className="text" variant="h2">
                Puzzle {currentPuzzleNumber}/10
            </Typography>
            <div className="bottomOverlay"></div>
        </Box>
    )
}
