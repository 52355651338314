import { Typography } from '@mui/material'
import { differenceInMinutes } from 'date-fns'
import { useEffect, useState } from 'react'
import { useResponsiveSizings } from '../../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreState } from '../../../store/hooks'

const Crown = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g filter="url(#filter0_i_13208_6192)">
            <path
                d="M4.36974 17.1085C4.36974 14.5265 6.50243 14.4343 6.51537 14.1847C6.51969 14.0849 6.51537 13.9808 6.48302 13.8896C6.38165 13.575 4.47542 8.54227 4.36974 8.25152C3.80683 8.1018 3.40352 7.55719 3.47253 6.93012C3.53508 6.37031 3.98584 5.91466 4.54228 5.8539C5.27772 5.77145 5.89886 6.34861 5.89886 7.07115C5.89886 7.26209 5.85357 7.44436 5.77592 7.60492L7.52288 9.34509V5.19212C7.00095 5.00551 6.64293 4.47392 6.72273 3.87072C6.79606 3.32393 7.24035 2.88563 7.786 2.82271C8.52145 2.73809 9.1469 3.31525 9.1469 4.03779C9.1469 4.39147 8.99593 4.71042 8.75653 4.93391L9.96215 8.52708L11.1678 4.93391C10.9284 4.71042 10.7774 4.39147 10.7774 4.03779C10.7774 3.31525 11.4007 2.73809 12.1383 2.82271C12.6839 2.88563 13.1282 3.32176 13.2016 3.87072C13.2814 4.47609 12.9233 5.00768 12.4014 5.19212V9.34509L14.1484 7.60492C14.0707 7.44219 14.0254 7.26209 14.0254 7.07115C14.0254 6.34861 14.6466 5.77362 15.382 5.8539C15.9385 5.91683 16.3892 6.37031 16.4518 6.93012C16.5208 7.55719 16.1175 8.1018 15.5546 8.25152C15.4489 8.54227 13.841 13.575 13.7374 13.8896C13.7072 13.9808 13.7008 14.0828 13.7051 14.1847C13.718 14.4343 15.909 14.418 15.909 17.1085"
                fill="#E4E4E4"
            />
        </g>
        <defs>
            <filter
                id="filter0_i_13208_6192"
                x="0"
                y="0"
                width="20"
                height="20"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="10" dy="10" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_13208_6192" />
            </filter>
        </defs>
    </svg>
)

const getFormattedTime = (endDate: string) => {
    const minutesLeft = differenceInMinutes(new Date(endDate), new Date())
    const daysLeft = Math.floor(minutesLeft / 60 / 24)
    const hoursLeft = Math.floor((minutesLeft / 60) % 24)

    const minutesLeftFriendly = `${minutesLeft > 0 ? `${minutesLeft % 60}m` : ''}`
    const hoursLeftFriendly = `${hoursLeft > 0 ? `${hoursLeft}h` : ''}`
    const daysLeftFriendly = `${daysLeft > 0 ? `${daysLeft}d` : ''}`

    return `${daysLeftFriendly} ${hoursLeftFriendly} ${hoursLeft < 1 && daysLeft < 1 ? minutesLeftFriendly : ''}`
}

export const WeeklyProgressHeader = () => {
    const {
        resolution: { isMobile },
    } = useResponsiveSizings()
    const weekProgress = useStoreState((state) => state.puzzleQuestView.weekProgress)
    const endTime = weekProgress?.endTime || ''
    const [timeLeft, setTimeLeft] = useState(getFormattedTime(endTime))

    if (!weekProgress) return null
    const crownsCollected = weekProgress.rewardsTotal

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(getFormattedTime(endTime))
        }, 1000)
        return () => clearInterval(interval)
    }, [endTime])

    return (
        <>
            <Typography fontSize="inherit" variant="body2" sx={{ fontVariant: 'tabular-nums' }}>
                {timeLeft.trim() ? `${timeLeft} ${isMobile ? 'left' : ''}` : 'Loading...'}
            </Typography>
            <Typography alignItems="flex-end" display="flex" fontSize="inherit" variant="body2">
                {crownsCollected} <Crown />
            </Typography>
        </>
    )
}
