import { Box } from '@mui/material'
import CorrectIcon from '../../../assets/icons/correct.svg?react'
import IncorrectIcon from '../../../assets/icons/incorrect.svg?react'
import { useStoreState } from '../../../store/hooks'

const getColor = (progress: number) => {
    if (progress === 0) return '#FF5C5C'
    if (progress === 1) return '#46A058'
    return 'transparent'
}

export const ProgressChest = () => {
    const dayProgress = useStoreState((state) => state.puzzleQuestView.dayProgress)
    const puzzleState = useStoreState((state) => state.puzzleQuestView.puzzleState)

    // array deconstruction according to the order of the progress sections in SVG
    const [third, fourth, fifth, first, second, seventh, sixth, tenth, ninth, eighth] = dayProgress.progress

    return (
        <Box
            position="relative"
            alignSelf="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
            maxHeight="164px"
            maxWidth="164px"
        >
            {puzzleState !== null && (
                <Box
                    sx={{
                        position: 'absolute',
                        left: '20%',
                        top: '20%',
                        right: '20%',
                        bottom: '20%',
                        '> svg': {
                            height: 'auto',
                            width: '100%',
                            backgroundColor: 'background.paper',
                            borderRadius: '100px',
                            path: { fill: getColor(puzzleState ? 1 : 0) },
                            animation: 'bounceIn 1.5s ease, fadeOut 1.5s ease 3s forwards',
                            '@keyframes bounceIn': {
                                '0%': {
                                    transform: 'scale(0)',
                                    opacity: 0,
                                },
                                '60%': {
                                    transform: 'scale(1.15)',
                                    opacity: 1,
                                },
                                '100%': {
                                    transform: 'scale(1)',
                                },
                            },
                            '@keyframes fadeOut': {
                                '0%': {
                                    opacity: 1,
                                },
                                '100%': {
                                    opacity: 0,
                                },
                            },
                        },
                    }}
                >
                    {puzzleState ? <CorrectIcon /> : <IncorrectIcon />}
                </Box>
            )}
            <svg
                id="quest-progress"
                width="100%"
                height="100%"
                viewBox="0 0 164 164"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_12717_37669)">
                    <g filter="url(#filter0_i_12717_37669)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M56.965 43H69.7451V47.8909H75.4341V43H88.4387V47.8909H94.0006V43H107.035L107.13 44.4585C107.52 50.4653 106.264 57.7051 99.3757 62.9863V99.2967C105.352 101.517 108.705 106.132 110.551 110.175C111.529 112.318 112.101 114.331 112.428 115.807C112.592 116.547 112.696 117.157 112.759 117.588C112.791 117.804 112.813 117.975 112.827 118.095C112.834 118.155 112.839 118.203 112.843 118.237L112.847 118.279L112.848 118.292L112.848 118.298C112.848 118.298 112.849 118.299 111.312 118.439L112.849 118.299L113 120H51L51.1514 118.299L52.6879 118.439C51.1514 118.299 51.1515 118.298 51.1515 118.298L51.1517 118.296L51.1521 118.292L51.1533 118.279L51.1574 118.237C51.1609 118.203 51.166 118.155 51.173 118.095C51.1871 117.975 51.2088 117.804 51.2405 117.588C51.304 117.157 51.4079 116.547 51.572 115.807C51.8994 114.331 52.4706 112.318 53.4491 110.175C55.295 106.132 58.6481 101.517 64.6243 99.2967V62.9863C57.7358 57.7051 56.4805 50.4653 56.8704 44.4585L56.965 43ZM54.4999 116.878H109.5C109.475 116.754 109.447 116.625 109.417 116.49C109.125 115.171 108.615 113.379 107.75 111.484C106.018 107.691 102.926 103.608 97.3867 101.914L96.2903 101.579V61.3839L96.9449 60.9176C102.671 56.8389 104.166 51.2912 104.107 46.1216H97.0861V51.0125H85.3532V46.1216H78.5196V51.0125H66.6596V46.1216H59.8928C59.8337 51.2912 61.3291 56.8389 67.0551 60.9176L67.7097 61.3839V101.579L66.6133 101.914C61.0735 103.608 57.9821 107.691 56.2502 111.484C55.3849 113.379 54.8752 115.171 54.5827 116.49C54.5528 116.625 54.5253 116.754 54.4999 116.878Z"
                            fill="#FFC58C"
                        />
                        <path
                            d="M85.2805 104.621L85.0839 106.188H82.9826V113.236H80.9674V106.188H78.7679V104.621H85.2805Z"
                            fill="#FFC58C"
                        />
                        <path
                            d="M82.1302 91.9234C82.7692 91.9234 83.3221 92.0145 83.7891 92.1969C84.2642 92.3792 84.6943 92.6569 85.0793 93.0298L84.1454 94.1363C83.5638 93.6556 82.9412 93.4152 82.2777 93.4152C81.9254 93.4152 81.6469 93.4898 81.4421 93.639C81.2373 93.7799 81.1349 93.9871 81.1349 94.2606C81.1349 94.4512 81.1799 94.6087 81.27 94.733C81.3602 94.849 81.5199 94.9609 81.7493 95.0686C81.9786 95.1764 82.3227 95.3048 82.7815 95.454C83.6498 95.7358 84.2888 96.0839 84.6984 96.4983C85.108 96.9044 85.3128 97.4846 85.3128 98.2388C85.3128 98.7775 85.1776 99.254 84.9073 99.6684C84.6369 100.075 84.2478 100.394 83.7399 100.626C83.232 100.849 82.6299 100.961 81.9336 100.961C81.2373 100.961 80.6188 100.849 80.0781 100.626C79.5456 100.402 79.0869 100.104 78.7019 99.7306L79.7218 98.5993C80.0494 98.8811 80.3853 99.0924 80.7294 99.2333C81.0816 99.3742 81.4626 99.4447 81.8721 99.4447C82.2899 99.4447 82.6176 99.3535 82.8552 99.1712C83.1009 98.9805 83.2238 98.7195 83.2238 98.3879C83.2238 98.1725 83.1788 97.9943 83.0887 97.8534C82.9985 97.7042 82.8429 97.5716 82.6217 97.4556C82.4005 97.3395 82.081 97.2152 81.6633 97.0826C80.7212 96.7925 80.0494 96.4361 79.648 96.0135C79.2548 95.5908 79.0582 95.0562 79.0582 94.4098C79.0582 93.9125 79.1893 93.4774 79.4514 93.1044C79.7136 92.7232 80.0781 92.4331 80.5451 92.2342C81.012 92.027 81.5404 91.9234 82.1302 91.9234Z"
                            fill="#FFC58C"
                        />
                        <path
                            d="M84.8224 79.6482L84.6135 81.1028H81.6152V83.1665H84.2325V84.5961H81.6152V86.7966H84.8224V88.2635H79.6V79.6482H84.8224Z"
                            fill="#FFC58C"
                        />
                        <path
                            d="M85.2969 72.9177C85.2969 73.5061 85.1699 74.0324 84.916 74.4965C84.6702 74.9607 84.2975 75.3253 83.7978 75.5906C83.298 75.8558 82.6877 75.9884 81.9669 75.9884C80.8855 75.9884 80.0622 75.7107 79.497 75.1554C78.9317 74.6001 78.6491 73.8542 78.6491 72.9177V67.1618H80.6643V72.8307C80.6643 73.386 80.7667 73.8004 80.9715 74.0739C81.1845 74.3391 81.5163 74.4717 81.9669 74.4717C82.4256 74.4717 82.7574 74.3391 82.9622 74.0739C83.1752 73.8004 83.2817 73.386 83.2817 72.8307V67.1618H85.2969V72.9177Z"
                            fill="#FFC58C"
                        />
                        <path
                            d="M83.8158 62.7311C84.3483 62.7311 84.8111 62.7974 85.2043 62.93C85.5976 63.0543 85.9744 63.2491 86.3348 63.5143L85.1183 65.118C84.6514 64.5876 84.1722 64.1773 83.6806 63.8873C83.1891 63.6055 82.5788 63.4646 81.8497 63.4646C81.0879 63.4646 80.4284 63.2905 79.8714 62.9424C79.3143 62.5861 78.8842 62.0722 78.5811 61.4009C78.2862 60.7296 78.1388 59.9215 78.1388 58.9767C78.1388 58.0484 78.2903 57.2445 78.5934 56.5649C78.9047 55.8853 79.3471 55.3673 79.9205 55.0109C80.494 54.6463 81.178 54.4639 81.9726 54.4639C83.185 54.4639 84.1271 54.8576 84.7988 55.6449C85.4788 56.4323 85.8187 57.5429 85.8187 58.9767C85.8187 60.7337 85.1511 61.9852 83.8158 62.7311ZM80.2523 58.9767C80.2523 60.0375 80.3957 60.8083 80.6824 61.289C80.9691 61.7697 81.3992 62.0101 81.9726 62.0101C82.5624 62.0101 82.9966 61.7739 83.2751 61.3014C83.5619 60.8207 83.7052 60.0458 83.7052 58.9767C83.7052 57.9075 83.5619 57.1368 83.2751 56.6644C82.9884 56.1919 82.5542 55.9557 81.9726 55.9557C81.391 55.9557 80.9568 56.1961 80.6701 56.6768C80.3916 57.1492 80.2523 57.9158 80.2523 58.9767Z"
                            fill="#FFC58C"
                        />
                        <path
                            d="M82 58.0878C81.8636 58.0878 81.7328 58.1505 81.6364 58.262C81.5399 58.3735 81.4858 58.5247 81.4858 58.6824C81.4858 58.9024 81.5894 59.0906 81.7429 59.1938V60.1689H82.2571V59.1938C82.4106 59.0906 82.5142 58.9024 82.5142 58.6824C82.5142 58.5247 82.4601 58.3735 82.3636 58.262C82.2672 58.1505 82.1364 58.0878 82 58.0878Z"
                            fill="#FFC58C"
                        />
                    </g>
                    <g filter="url(#filter1_i_12717_37669)">
                        <path
                            d="M158.08 106.735C152.856 122.802 142.681 136.802 129.011 146.73L117.258 130.548C127.51 123.102 135.142 112.601 139.06 100.552L158.08 106.735Z"
                            fill={getColor(first)}
                        />
                    </g>
                    <path
                        d="M158.08 106.735C152.856 122.802 142.681 136.802 129.011 146.73L117.258 130.548C127.51 123.102 135.142 112.601 139.06 100.552L158.08 106.735Z"
                        stroke="#FFC58C"
                        strokeWidth="4"
                    />
                    <g filter="url(#filter2_i_12717_37669)">
                        <path
                            d="M129.129 146.644C115.477 156.597 99.0251 161.972 82.1307 162L82.098 142C94.7688 141.979 107.108 137.948 117.346 130.483L129.129 146.644Z"
                            fill={getColor(second)}
                        />
                    </g>
                    <path
                        d="M129.129 146.644C115.477 156.597 99.0251 161.972 82.1307 162L82.098 142C94.7688 141.979 107.108 137.948 117.346 130.483L129.129 146.644Z"
                        stroke="#FFC58C"
                        strokeWidth="4"
                    />
                    <g filter="url(#filter3_i_12717_37669)">
                        <path
                            d="M82 2C98.8943 2 115.355 7.3484 129.023 17.2787L117.267 33.459C107.016 26.0113 94.6708 22 82 22V2Z"
                            fill={getColor(third)}
                        />
                    </g>
                    <path
                        d="M82 2C98.8943 2 115.355 7.3484 129.023 17.2787L117.267 33.459C107.016 26.0113 94.6708 22 82 22V2Z"
                        stroke="#FFC58C"
                        strokeWidth="4"
                    />
                    <g filter="url(#filter4_i_12717_37669)">
                        <path
                            d="M129.291 17.4741C142.917 27.4609 153.032 41.5052 158.186 57.5941L139.14 63.6956C135.274 51.6289 127.688 41.0956 117.468 33.6055L129.291 17.4741Z"
                            fill={getColor(fourth)}
                        />
                    </g>
                    <path
                        d="M129.291 17.4741C142.917 27.4609 153.032 41.5052 158.186 57.5941L139.14 63.6956C135.274 51.6289 127.688 41.0956 117.468 33.6055L129.291 17.4741Z"
                        stroke="#FFC58C"
                        strokeWidth="4"
                    />
                    <g filter="url(#filter5_i_12717_37669)">
                        <path
                            d="M158.138 57.4425C163.324 73.5211 163.286 90.8289 158.031 106.885L139.023 100.664C142.965 88.6217 142.993 75.6409 139.103 63.5819L158.138 57.4425Z"
                            fill={getColor(fifth)}
                        />
                    </g>
                    <path
                        d="M158.138 57.4425C163.324 73.5211 163.286 90.8289 158.031 106.885L139.023 100.664C142.965 88.6217 142.993 75.6409 139.103 63.5819L158.138 57.4425Z"
                        stroke="#FFC58C"
                        strokeWidth="4"
                    />
                    <g filter="url(#filter6_i_12717_37669)">
                        <path
                            d="M5.92004 106.735C11.1436 122.802 21.3195 136.802 34.9891 146.73L46.7418 130.548C36.4896 123.102 28.8577 112.601 24.94 100.552L5.92004 106.735Z"
                            fill={getColor(sixth)}
                        />
                    </g>
                    <path
                        d="M5.92004 106.735C11.1436 122.802 21.3195 136.802 34.9891 146.73L46.7418 130.548C36.4896 123.102 28.8577 112.601 24.94 100.552L5.92004 106.735Z"
                        stroke="#FFC58C"
                        strokeWidth="4"
                    />
                    <g filter="url(#filter7_i_12717_37669)">
                        <path
                            d="M34.8714 146.644C48.523 156.597 64.9749 161.972 81.8693 162L81.902 142C69.2312 141.979 56.8923 137.948 46.6536 130.483L34.8714 146.644Z"
                            fill={getColor(seventh)}
                        />
                    </g>
                    <path
                        d="M34.8714 146.644C48.523 156.597 64.9749 161.972 81.8693 162L81.902 142C69.2312 141.979 56.8923 137.948 46.6536 130.483L34.8714 146.644Z"
                        stroke="#FFC58C"
                        strokeWidth="4"
                    />
                    <g filter="url(#filter8_i_12717_37669)">
                        <path
                            d="M82 2C65.1057 2 48.645 7.3484 34.9772 17.2787L46.7329 33.459C56.9837 26.0113 69.3292 22 82 22V2Z"
                            fill={getColor(eighth)}
                        />
                    </g>
                    <path
                        d="M82 2C65.1057 2 48.645 7.3484 34.9772 17.2787L46.7329 33.459C56.9837 26.0113 69.3292 22 82 22V2Z"
                        stroke="#FFC58C"
                        strokeWidth="4"
                    />
                    <g filter="url(#filter9_i_12717_37669)">
                        <path
                            d="M34.7094 17.4741C21.0828 27.4609 10.9677 41.5052 5.81369 57.5941L24.8603 63.6956C28.7258 51.6289 36.3121 41.0956 46.532 33.6055L34.7094 17.4741Z"
                            fill={getColor(ninth)}
                        />
                    </g>
                    <path
                        d="M34.7094 17.4741C21.0828 27.4609 10.9677 41.5052 5.81369 57.5941L24.8603 63.6956C28.7258 51.6289 36.3121 41.0956 46.532 33.6055L34.7094 17.4741Z"
                        stroke="#FFC58C"
                        strokeWidth="4"
                    />
                    <g filter="url(#filter10_i_12717_37669)">
                        <path
                            d="M5.86244 57.4425C0.676407 73.5211 0.713654 90.8289 5.96886 106.885L24.9766 100.664C21.0352 88.6217 21.0073 75.6409 24.8968 63.5819L5.86244 57.4425Z"
                            fill={getColor(tenth)}
                        />
                    </g>
                    <path
                        d="M5.86244 57.4425C0.676407 73.5211 0.713654 90.8289 5.96886 106.885L24.9766 100.664C21.0352 88.6217 21.0073 75.6409 24.8968 63.5819L5.86244 57.4425Z"
                        stroke="#FFC58C"
                        strokeWidth="4"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_i_12717_37669"
                        x="51"
                        y="43"
                        width="62"
                        height="77"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="8" dy="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12717_37669" />
                    </filter>
                    <filter
                        id="filter1_i_12717_37669"
                        x="114.465"
                        y="98.0312"
                        width="46.1367"
                        height="51.4922"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="8" dy="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12717_37669" />
                    </filter>
                    <filter
                        id="filter2_i_12717_37669"
                        x="80.0938"
                        y="127.689"
                        width="51.8281"
                        height="36.3145"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="8" dy="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12717_37669" />
                    </filter>
                    <filter
                        id="filter3_i_12717_37669"
                        x="80"
                        y="0"
                        width="51.8164"
                        height="36.252"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="8" dy="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12717_37669" />
                    </filter>
                    <filter
                        id="filter4_i_12717_37669"
                        x="114.672"
                        y="14.6777"
                        width="46.0273"
                        height="51.5332"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="8" dy="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12717_37669" />
                    </filter>
                    <filter
                        id="filter5_i_12717_37669"
                        x="136.5"
                        y="54.9258"
                        width="27.5"
                        height="54.4824"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="8" dy="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12717_37669" />
                    </filter>
                    <filter
                        id="filter6_i_12717_37669"
                        x="3.39844"
                        y="98.0312"
                        width="46.1367"
                        height="51.4922"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="8" dy="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12717_37669" />
                    </filter>
                    <filter
                        id="filter7_i_12717_37669"
                        x="32.0781"
                        y="127.689"
                        width="51.8281"
                        height="36.3145"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="8" dy="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12717_37669" />
                    </filter>
                    <filter
                        id="filter8_i_12717_37669"
                        x="32.1836"
                        y="0"
                        width="51.8164"
                        height="36.252"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="8" dy="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12717_37669" />
                    </filter>
                    <filter
                        id="filter9_i_12717_37669"
                        x="3.30078"
                        y="14.6777"
                        width="46.0273"
                        height="51.5332"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="8" dy="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12717_37669" />
                    </filter>
                    <filter
                        id="filter10_i_12717_37669"
                        x="0"
                        y="54.9258"
                        width="27.5"
                        height="54.4824"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="8" dy="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12717_37669" />
                    </filter>
                    <clipPath id="clip0_12717_37669">
                        <rect width="164" height="164" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </Box>
    )
}
