import { Box } from '@mui/material'
import { useStoreState } from '../../../store/hooks'
import { DailyCooldown } from '../components/DailyCooldown'
import { DailyProgress } from '../components/DailyProgress'
import { PuzzleQuestControls } from '../components/PuzzleQuestControls'
import { PuzzleQuestNavigation } from '../components/PuzzleQuestNavigation'
import { StatusHeader } from '../components/StatusHeader'
import { WeeklyProgress } from '../components/WeeklyProgress'
import { WeeklyProgressHeader } from '../components/WeeklyProgressHeader'

export const MobileWeeklyProgress = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Box
                sx={{
                    // workaround to put it in header
                    fontSize: '20px',
                    flexGrow: 1,
                    position: 'absolute',
                    left: '132px',
                    right: '66px',
                    top: '16px',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    zIndex: 1300,
                    gap: '16px',
                    '@media (min-width: 600px)': {
                        justifyContent: 'flex-start',
                    },
                }}
            >
                <WeeklyProgressHeader />
            </Box>
            <WeeklyProgress />
        </Box>
    )
}

export const MobilePQ = () => {
    const dayProgress = useStoreState((state) => state.puzzleQuestView.dayProgress)

    return (
        <>
            {dayProgress.completed ? (
                <>
                    <MobileWeeklyProgress />
                    <DailyCooldown />
                    <PuzzleQuestNavigation />
                </>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        padding: '0px 16px',
                        paddingBottom: '16px',
                        h5: {
                            padding: '0 !important',
                            height: 'auto !important',
                        },
                    }}
                >
                    <StatusHeader />
                    <PuzzleQuestControls />
                    <DailyProgress />
                </Box>
            )}
        </>
    )
}
