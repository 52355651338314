import { Palette, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Typography from '@mui/material/Typography'
import isEqual from 'lodash-es/isEqual'
import React, { useEffect, useRef, useState } from 'react'
import { AreaType } from '../GlobalHeader'
import { FramedAvatar } from './FramedAvatar'
import GlobalLink from './GlobalLink'

const DropdownStyle = (palette: Palette) => {
    return {
        boxShadow: 'none',
        '&.dropdown': {
            backgroundColor: 'transparent',
            '& .popper-menu-list': {
                boxSizing: 'border-box',
                border: 'solid 1px transparent',
                borderRadius: '5px',
                background: 'linear-gradient(180deg, #17232F 0%, #0E1924 16.06%, #06111C 100%)',
                backgroundClip: 'padding-box',
                display: 'flex',
                flexDirection: 'column',
                gap: '0',
                height: 'auto',
                // width: '250px',
                minWidth: '270px',
                '&:before': {
                    content: "''",
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: '-1',
                    margin: '-1px',
                    borderRadius: 'inherit',
                    background: 'linear-gradient(180deg, #16292C 0%, #092028 100%)',
                },
                '& a': {
                    textDecoration: 'none',
                },
                '& .popper-items': {
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    borderLeft: '2px solid transparent',
                    px: 3,
                    '&.active': {
                        color: 'primary.main',
                        borderLeft: '2px solid',
                        borderColor: 'primary.main',

                        '> svg path': {
                            //     fill: palette.primary.main,
                            //     stroke: palette.primary.main,
                        },
                    },
                },
                '& .popper-typo': {
                    fontFamily: 'Fira Sans',
                    fontSize: '20px',
                    letterSpacing: 0,
                    lineHeight: '200%',
                },
            },
        },
    }
}

export type DropdownMenuItem = {
    link?: string
    onClick?: () => void
    title: string | JSX.Element
    icon?: JSX.Element
    target?: string
    new?: boolean
}

export interface DropdownProps {
    menuButtonTitle?: JSX.Element
    dropdownItems: DropdownMenuItem[]
    avatarUrl?: string
    area: AreaType
    Link: any
    reward?: {
        tier: number
        season: string
    }
}

export default function Dropdown(props: DropdownProps) {
    const { menuButtonTitle, dropdownItems, avatarUrl, area, Link, reward } = props
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLButtonElement>(null)
    const { palette } = useTheme()

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }
        setOpen(false)
    }

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        } else if (event.key === 'Escape') {
            setOpen(false)
        }
    }

    const getActivePage = (link: string) => {
        if (isEqual(link, window.location.pathname)) {
            return 'active'
        }
        return ''
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <Paper className="dropdown" sx={DropdownStyle(palette)}>
            <Box
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1,
                    cursor: 'pointer',
                }}
            >
                {(avatarUrl || avatarUrl === '') && (
                    <FramedAvatar
                        avatar={avatarUrl || ''}
                        season={reward?.season}
                        reward={reward?.tier}
                        sx={{
                            height: '34px',
                            width: '34px',
                        }}
                    />
                )}
                {menuButtonTitle && menuButtonTitle}
            </Box>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                className="popper"
                sx={{
                    '& p': {
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'space-between',
                    },
                    'p > span': {
                        height: '16px',
                        lineHeight: '10px',
                        color: (theme) => theme.palette.error.light,
                        fontFamily: 'Fira Sans',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        padding: '2px 4px',
                        mx: '8px',
                        border: (theme) => `1px solid ${theme.palette.error.light}`,
                    },
                }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'top',
                        }}
                    >
                        <Paper sx={{ backgroundColor: 'transparent' }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                    className="popper-menu-list"
                                >
                                    {dropdownItems.map((menuItem: DropdownMenuItem, index) => {
                                        if (menuItem.link)
                                            return (
                                                <GlobalLink
                                                    key={menuItem.link}
                                                    href={menuItem.link}
                                                    target={menuItem.target ? menuItem.target : '_self'}
                                                    Link={Link}
                                                    area={area}
                                                >
                                                    <MenuItem
                                                        key={menuItem.link}
                                                        className={`popper-items ${getActivePage(menuItem.link)}`}
                                                        onClick={() => {
                                                            setOpen(false)
                                                        }}
                                                    >
                                                        <Typography className="popper-typo">
                                                            {menuItem.title}
                                                        </Typography>
                                                        {menuItem.icon && menuItem.icon}
                                                    </MenuItem>
                                                </GlobalLink>
                                            )
                                        else if (menuItem.onClick)
                                            return (
                                                <MenuItem
                                                    key={`${menuItem.link}-${index}`}
                                                    className="popper-items"
                                                    onClick={() => {
                                                        menuItem.onClick && menuItem.onClick()
                                                        setOpen(false)
                                                    }}
                                                >
                                                    <Typography className="popper-typo">{menuItem.title}</Typography>
                                                    {menuItem.icon && menuItem.icon}
                                                </MenuItem>
                                            )
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Paper>
    )
}
