import { format } from "date-fns"
import { NavigateFunction } from "react-router-dom"

const navigateToDate = (navigate: NavigateFunction, date?: Date, isNext?: boolean) => {
    let newDate = new Date()
    if (date) {
        newDate = new Date(date)
    }
    if (isNext !== undefined) {
        newDate.setDate(newDate.getDate() + (isNext ? 1 : -1))
    }
    navigate(`/daily-puzzle/${format(newDate, 'yyyy-MM-dd')}`)
}

export default navigateToDate
