import { SxProps, Theme, Typography } from '@mui/material'
import { differenceInSeconds } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useStoreState } from '../../../store/hooks'

const calculateAndFormatTimeLeft = (
    endTime: string,
    callback?: () => void,
): [hours: number, minutes: number, seconds: number] => {
    const difference = differenceInSeconds(endTime, new Date()) // Time difference in seconds
    if (difference > 0) {
        const hours = Math.floor(difference / 3600) // 1 hour = 3600 seconds
        const minutes = Math.floor((difference % 3600) / 60) // Remaining seconds converted to minutes
        const seconds = difference % 60 // Remaining seconds
        return [hours, minutes, seconds]
    } else {
        callback?.()
        return [0, 0, 0]
    }
}

export const Countdown: React.FC<{
    endTime: string
    callback: () => void
    suffix?: string
    sx?: SxProps<Theme>
}> = ({ endTime, callback, suffix, sx }) => {
    const puzzleId = useStoreState((state) => state.puzzleQuestView.puzzleId)
    const [timeLeft, setTimeLeft] = useState(calculateAndFormatTimeLeft(endTime))
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        puzzleId && setLoading(false)
    }, [puzzleId])

    useEffect(() => {
        let timeout
        const interval = setInterval(() => {
            const improvedCallback = () => {
                setLoading(true)
                timeout = setTimeout(() => {
                    callback()
                    setLoading(false)
                }, 3000)

                clearInterval(interval)
            }
            const newTimeLeft = calculateAndFormatTimeLeft(endTime, improvedCallback)

            setTimeLeft(newTimeLeft)
        }, 1000)

        return () => {
            clearInterval(interval)
            clearTimeout(timeout)
        } // Cleanup on unmount
    }, [endTime])

    const formatTime = (value) => String(value).padStart(2, '0')
    if (loading) return <Typography sx={{ ...sx }}>Loading...</Typography>

    return (
        <Typography
            sx={{
                fontVariantNumeric: 'tabular-nums',
                ...sx,
            }}
        >
            {formatTime(timeLeft[0])}
            <span>:</span>
            {formatTime(timeLeft[1])}
            <span>:</span>
            {formatTime(timeLeft[2])}
            {suffix}
        </Typography>
    )
}
