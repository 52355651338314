import { Avatar, Box } from '@mui/material'
import { FC } from 'react'
import { getPuzzleQuestAsset } from '../../helpers/getPuzzleQuestAsset'

type FramedAvatarProps = {
    reward: number | undefined
    season: string | undefined
    avatar: string
    isOnline?: boolean
    stripe?: boolean
    sx?: any
}

export const FramedAvatar: FC<FramedAvatarProps> = ({ avatar, reward, stripe, sx, isOnline, season = 'shields' }) => {
    const haveReward = Boolean(reward)

    return (
        <Box sx={{ position: 'relative', ...sx }}>
            <Avatar
                sx={{
                    zIndex: 1,
                    height: '100%',
                    width: '100%',
                    position: 'relative',
                    border: haveReward ? 'unset' : '1px solid white',
                }}
                src={avatar}
                variant="square"
            />
            {haveReward && reward && (
                <img
                    style={{
                        filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5))',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        zIndex: 2,
                    }}
                    width="100%"
                    height="100%"
                    src={getPuzzleQuestAsset(season, reward, 'frame')}
                />
            )}
            {haveReward && reward && (
                <img
                    style={{
                        transform: 'translate(0%, 40%)',
                        filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5))',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        left: 0,
                        zIndex: 4,
                        height: '40%',
                        width: '100%',
                    }}
                    src={getPuzzleQuestAsset(season, reward, 'active')}
                />
            )}

            {isOnline !== undefined && (
                <Box
                    sx={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        height: '25%',
                        width: '25%',
                        transform: 'translate(25%, 25%)',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        zIndex: 4,
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '10%',
                            right: '10%',
                            width: '80%',
                            height: '80%',
                            borderRadius: '50%',
                            boxShadow: 'inset 1px 1px 1px 0px rgba(0, 0, 0, 0.25)',
                            filter: 'drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.5))',
                            zIndex: 3,
                            backgroundColor: (theme) =>
                                isOnline ? theme.palette.success.light : theme.palette.background.paper,
                        }}
                    />

                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            zIndex: 2,
                            width: '100%',
                            height: '100%',
                            border: (theme) => `1px solid white`,
                            borderRadius: '50%',
                            borderImage: reward ? `url(${getPuzzleQuestAsset(season, reward, 'frame')}) 2` : 'none',
                            backgroundImage: reward ? `url(${getPuzzleQuestAsset(season, reward, 'frame')})` : 'none',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />
                </Box>
            )}

            {stripe && haveReward && reward && (
                <Box
                    sx={{
                        position: 'absolute',
                        width: { xs: '200%', md: '250%' },
                        bottom: { xs: '-30%', md: '-40%' },
                        left: { xs: '-50%', md: '-75%' },
                        right: { xs: '50%', md: '75%' },
                        zIndex: 0,
                    }}
                >
                    <img width="100%" height="100%" src={getPuzzleQuestAsset(season, reward, 'stripe')} />
                </Box>
            )}
        </Box>
    )
}
