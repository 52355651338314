import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, useTheme } from '@mui/material'
import { debounce } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { ChallengeWrapper, UserInfo } from '../../../components/matchmaking/ChallengeCard'
import { Rules } from '../../../sharedComponents/src/globalHeader/GlobalHeader'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { LiveGameData } from '../../../store/types'

export async function getMatchesInProgress(
    token: string,
    limit: number,
    offset: number,
    gameType: string,
): Promise<any> {
    try {
        const response = await fetch(
            `${
                import.meta.env.VITE_REACT_APP_GS_URL
            }/api/games_in_progress?gameType=${gameType}&limit=${limit}&offset=${offset}&token=${token}`,
            {
                method: 'GET',
            },
        )
        const data = await response.json()

        if (!response.ok) {
            return false
        }
        return !!data && data
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export interface ObserveTabProps {
    liveGame: boolean
}

export const ObserveTab = (props: ObserveTabProps) => {
    const { liveGame } = props
    const token = useStoreState((state) => state.token)
    const rules = useStoreState((state) => state.rules)
    const sendSubscribe = useStoreActions((state) => state.observeView.sendSubscribe)
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)
    const setActiveTab = useStoreActions((state) => state.observeView.setActiveTab)
    const getScore = useStoreActions((state) => state.matchMaker.getScore)
    const [matches, setMatches] = useState([] as LiveGameData[])

    const liveGamesEnabled = rules.some((rule) => rule === Rules.FULL_ACCESS || rule === Rules.LIVE_GAMES)

    const [gameType, setGameType] = useState('all')
    let limit = 50
    const [offset, setOffset] = useState(0)
    const theme = useTheme()

    const fetchMatchesInProgress = useCallback(
        // debounce to prevent double fetch
        debounce(async () => {
            if (!token) return
            const data = await getMatchesInProgress(token, limit, offset, gameType)

            if (data.error || !data) {
                setMatches([])
            } else {
                setMatches(data)
            }
        }, 10),
        [token, limit, offset, gameType],
    )

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchMatchesInProgress()
        }, 5000)
        return () => clearInterval(intervalId)
    }, [fetchMatchesInProgress, token])

    useEffect(() => {
        fetchMatchesInProgress()
    }, [gameType, offset])

    const onShowMoreClick = () => {
        setOffset(offset + 10)
    }

    const onShowLessClick = () => {
        setOffset(offset - 10)
    }

    const handleTypeSelect = (e) => {
        setGameType(e.target.value)
        setOffset(0)
    }

    const onLiveGameClick = (game: LiveGameData) => {
        if (!liveGamesEnabled) {
            setOverlayTrigger('joinUs')
        } else {
            sendSubscribe({
                matchId: game.matchId,
                liveGameData: game,
            })
            getScore({ challengerId: game.player1.playerId, challengeeId: game.player2.playerId })
            setActiveTab(1)
        }
    }

    return (
        <Paper
            square
            sx={{
                border: 'solid',
                display: 'flex',
                flexGrow: 1,
                minHeight: '250px',
                flexFlow: 'column',
            }}
        >
            <FormControl fullWidth sx={{ marginTop: 1 }}>
                <InputLabel sx={{ ml: 1 }} variant="standard" id="sort-select-label">
                    Sort By Game:
                </InputLabel>
                <Select
                    labelId="sort-select-label"
                    sx={{ textTransform: 'capitalize' }}
                    defaultValue={gameType}
                    value={gameType}
                    variant="standard"
                    onChange={handleTypeSelect}
                >
                    {['all', 'bullet', 'blitz', 'rapid', 'classic'].map((item) => (
                        <MenuItem
                            sx={{ fontFamily: 'Space Grotesk', textTransform: 'capitalize' }}
                            key={item}
                            value={item}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Box overflow="auto" flexGrow={1} minHeight="0" sx={{ flex: 1, overflowY: 'auto' }}>
                {matches.map((game: LiveGameData) => (
                    <ChallengeWrapper
                        key={game.matchId}
                        type="view"
                        handleAction={() => onLiveGameClick(game)}
                        game={game}
                        ratingType={game.timeMode.name}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-end"
                            flexGrow={1}
                            minWidth={'100px'}
                        >
                            <UserInfo
                                type="view"
                                direction="row"
                                player={{
                                    ...game.player1,
                                    username: game.player1.userName,
                                    rating: !!game.player1.ratings
                                        ? game.player1.ratings[game.timeMode.name].rating
                                        : 0,
                                    is_provisional: !!game.player1.ratings
                                        ? game.player1.ratings[game.timeMode.name].is_provisional
                                        : false,
                                    color: game.player1.playerColor,
                                    nationality: game.player1.countryId || 'WORLD',
                                    reward: {
                                        tier: game.player1.reward?.tier,
                                        season: game.player1.reward?.season,
                                    },
                                }}
                                ratingType={game.timeMode.name}
                            />

                            <UserInfo
                                type="view"
                                direction="row"
                                player={{
                                    ...game.player2,
                                    username: game.player2.userName,
                                    rating: !!game.player2.ratings
                                        ? game.player2.ratings[game.timeMode.name].rating
                                        : 0,
                                    is_provisional: !!game.player2.ratings
                                        ? game.player2.ratings[game.timeMode.name].is_provisional
                                        : false,
                                    color: game.player2.playerColor,
                                    nationality: game.player2.countryId || 'WORLD',
                                    reward: {
                                        tier: game.player2.reward?.tier,
                                        season: game.player2.reward?.season,
                                    },
                                }}
                                ratingType={game.timeMode.name}
                            />
                        </Box>
                    </ChallengeWrapper>
                ))}
            </Box>

            <Box
                justifyContent="center"
                alignItems="center"
                gap={1}
                display={offset <= 0 || matches === undefined || matches.length < limit ? 'none' : 'flex'}
            >
                <Button
                    sx={{ justifyContent: 'flex-start' }}
                    size="small"
                    fullWidth
                    variant="text"
                    onClick={onShowLessClick}
                    disabled={offset <= 0}
                    startIcon={<ArrowBackIosNewIcon />}
                >
                    Previous
                </Button>
                <Button
                    sx={{ justifyContent: 'flex-end' }}
                    size="small"
                    fullWidth
                    variant="text"
                    onClick={onShowMoreClick}
                    disabled={matches === undefined || matches.length < limit}
                    endIcon={<ArrowForwardIosIcon />}
                >
                    Next
                </Button>
            </Box>
        </Paper>
    )
}
