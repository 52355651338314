import { Box, Typography } from '@mui/material'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { Countdown } from './Countdown'
import { ProgressChest } from './ProgressChest'

export const DailyProgress = () => {
    const getPuzzle = useStoreActions((actions) => actions.puzzleQuestView.getPuzzle)
    const endTime = useStoreState((state) => state.puzzleQuestView.dayProgress.endTime)

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexGrow: 1, justifyContent: 'space-between' }}>
            <Box>
                <Typography
                    noWrap
                    variant="h5"
                    textTransform="none"
                    sx={{
                        fontWeight: '400 !important',
                        fontFamily: 'Space Grotesk',
                        fontSize: '24px',
                        lineHeight: '26px',
                        mb: 0.5,
                    }}
                >
                    Daily Challenge:
                </Typography>
                <Countdown
                    endTime={endTime}
                    callback={getPuzzle}
                    suffix=" time remaining"
                    sx={{
                        fontSize: '16px',
                    }}
                />
            </Box>
            <ProgressChest />
        </Box>
    )
}
