import CancelIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, Typography } from '@mui/material'
import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react'
import HintIcon from '../../../../assets/icons/hint.svg?react'
import { nameOfSquare, pieceSymbol } from '../../../../chess/basics'
import { Color, GameTree } from '../../../../chess/types'
import useCorrectPieceColor from '../../../../hooks/useCorrectPieceColor'
import { Square } from '../../../../react-chessboard/src/chessboard/types'
import { PiecesTheme, piecesProvider } from '../../../../sharedComponents/src/globalHeader/common/PiecesProvider'
import { colors } from '../../../../sharedComponents/src/globalHeader/theme/colors'

export type NotationProps = {
    notations: NotationsType[]
    setNotations: Dispatch<SetStateAction<NotationsType[]>>
    currentHint: Square | undefined
    gameTree: GameTree
    usedShowSolution: boolean
    puzzleState: boolean | null
    currentPositionId: string
    setCurrentPositionId: (nextPositionId: string) => void
    myColor: Color
    disabled: boolean
}

export type NotationsType = {
    location: string
    id: string
    piece: string
    playersMove: boolean
    usedHint: boolean
    usedShowSolution: boolean
    isLost: boolean
    isCorrect: boolean
    nextPositionId: string
}

const getNotationItem = (notation: NotationsType) => {
    if (notation.isLost) {
        return (
            <Typography variant="body2">
                <span>{notation.location}</span>: <CancelIcon /> Incorrect
            </Typography>
        )
    } else if (notation.usedShowSolution) {
        return (
            <Typography variant="body2" color={colors.glow.main}>
                <span>{notation.location}</span>
            </Typography>
        )
    } else if (notation.usedHint) {
        return (
            <Typography variant="body2">
                <span style={{ color: colors.glow.main }}>{notation.location}</span>: <HintIcon /> Hint
            </Typography>
        )
    } else if (notation.isCorrect) {
        return (
            <Typography variant="body2">
                <span>{notation.location}</span>: <CheckCircleIcon /> Correct
            </Typography>
        )
    } else {
        return (
            <Typography variant="body2">
                <span>{notation.location}</span>
            </Typography>
        )
    }
}

const pieces = piecesProvider(PiecesTheme.DEFAULT)

export const Notations: FC<NotationProps> = ({
    currentHint,
    gameTree,
    usedShowSolution,
    puzzleState,
    setCurrentPositionId,
    currentPositionId,
    notations,
    setNotations,
    myColor,
    disabled,
}) => {
    const containerRef = useRef<HTMLDivElement>(null)

    const scrollToBottom = () => {
        const container = containerRef.current
        if (container) {
            container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' })
        }
    }

    useEffect(() => {
        scrollToBottom()
    }, [notations])

    useEffect(() => {
        if (gameTree.moves.length && gameTree.moves.length > notations.length) {
            // Add new move to notations
            const newItemIndex = notations.length
            const move = gameTree.moves[newItemIndex]
            const position = gameTree.positions[newItemIndex]
            const piece = pieceSymbol(position.position, move.move.from)
            const playersMove = position.position.turn === myColor
            setNotations((prevNotations) =>
                prevNotations.concat({
                    location: nameOfSquare(move.move.to),
                    id: move.id,
                    piece,
                    playersMove,
                    usedHint: nameOfSquare(move.move.from) === currentHint,
                    usedShowSolution: playersMove && usedShowSolution,
                    isLost: puzzleState === false,
                    isCorrect: playersMove && puzzleState !== false,
                    nextPositionId: move.nextPositionId,
                }),
            )
        } else if (gameTree.moves.length && gameTree.moves.length < notations.length) {
            // remove last incorrect move
            setNotations((prevNotations) => {
                prevNotations.pop()
                return prevNotations
            })
        } else if (!gameTree.moves.length) {
            // reset moves
            notations.length && setNotations([])
        }
    }, [gameTree.moves.length, currentHint, usedShowSolution, puzzleState])

    const currentPositionIsLast = currentPositionId === notations[notations.length - 1]?.nextPositionId

    const onPositionChange = (move: NotationsType) => {
        if (disabled) return
        setCurrentPositionId(move.nextPositionId)
    }

    return (
        <Box
            ref={containerRef}
            sx={{
                margin: '0 auto',

                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridAutoRows: 'min-content',
                width: '100%',
                px: { xs: '8px', md: '15%' },
                minHeight: { xs: '100px', md: '50px' },
                overflow: 'auto',
                flexGrow: 1,
            }}
        >
            {!notations[0]?.playersMove && (
                <Typography height="48px" padding={'8px 14px'} fontSize="18px" fontWeight="bold" variant="body2">
                    ...
                </Typography>
            )}
            {notations.map((move) => (
                <Box
                    key={move.id}
                    onClick={() => onPositionChange(move)}
                    sx={{
                        cursor: disabled ? 'default' : 'pointer',
                        justifySelf: move.playersMove ? 'flex-start' : 'flex-end',
                        display: 'flex',
                        height: '48px',
                        alignItems: 'center',
                        borderRadius: '0.25rem',
                        padding: '4px',
                        ':hover': {
                            backgroundColor: disabled ? 'transparent' : 'background.default',
                        },
                        backgroundColor:
                            move.nextPositionId === currentPositionId && !currentPositionIsLast
                                ? 'background.default'
                                : 'transparent',
                        border: `1px solid ${move.nextPositionId === currentPositionId && !currentPositionIsLast ? 'white' : 'transparent'} !important`,
                        ':active': {
                            transform: disabled ? 'scale(1)' : 'scale(0.9)',
                        },
                    }}
                >
                    <Box
                        width="32px"
                        height="32px"
                        sx={useCorrectPieceColor(
                            move.usedShowSolution ? colors.glow.main : move.piece[0] === 'b' ? 'black' : '',
                            move.piece[0] === 'b' && !move.usedShowSolution ? 'white' : '',
                        )}
                    >
                        {pieces[move.piece]?.({ isDragging: false, squareWidth: '100%' })}
                    </Box>
                    <Box
                        sx={{
                            userSelect: 'none',
                            svg: {
                                width: '30px',
                                height: '30px',
                                margin: '0 0.25rem',
                                ' > path': {
                                    fill: move.isLost
                                        ? colors.error.dark
                                        : move.isCorrect && !move.usedHint
                                          ? colors.success.dark
                                          : '',
                                },
                            },
                            '> p': {
                                display: 'flex',
                                alignItems: 'center',
                            },
                        }}
                    >
                        {getNotationItem(move)}
                    </Box>
                </Box>
            ))}
        </Box>
    )
}
