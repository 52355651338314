import { useMemo, useState } from 'react'
import { Chessboard } from '../../../../react-chessboard/src'
import { Piece as BoardPiece, BoardPosition } from '../../../../react-chessboard/src/chessboard/types'
import { piecesProvider } from '../../../../sharedComponents/src/globalHeader/common/PiecesProvider'
import { boardColors } from '../../../../sharedComponents/src/globalHeader/theme/colors'
import { useStoreState } from '../../../../store/hooks'

export type SetupChessBoardProps = {
    fen: string
    flipped: boolean
    deleteModeOn: boolean
    selectModeOn: boolean
    selectedPiece: BoardPiece | undefined
    updatePosition: (currentPosition: BoardPosition) => void
}

export function SetupChessBoard(props: SetupChessBoardProps) {
    let { fen, flipped, updatePosition, selectedPiece, deleteModeOn, selectModeOn } = props
    const [currentBoardPosition, setCurrentBoardPosition] = useState<BoardPosition>({})
    const [squareStyles, setSquareStyles] = useState({})
    const settings = useStoreState((state) => state.gameView.settings)

    let boardColor = boardColors[settings.boardStyle] || boardColors.default

    const customPieces = useMemo(() => piecesProvider(settings.pieceStyle), [settings.pieceStyle])

    return (
        <Chessboard
            position={fen}
            customDarkSquareStyle={boardColor.secondary}
            customLightSquareStyle={boardColor.main}
            promotionDialogVariant={'modal'}
            customSquareStyles={{ ...squareStyles }}
            showBoardNotation={settings.coordinates}
            boardOrientation={flipped ? 'black' : 'white'}
            customPieces={customPieces}
            areArrowsAllowed={true}
            getPositionObject={(currentPosition: BoardPosition) => {
                updatePosition(currentPosition)
                setCurrentBoardPosition(currentPosition)
            }}
            onSquareClick={(square) => {
                // if we are in delete mode, delete the piece on the square else if we are in select mode, add the selected piece to the square
                if (deleteModeOn) {
                    let currentPiecesOnBoard = Object.keys(currentBoardPosition)
                    if (currentPiecesOnBoard.includes(square)) {
                        let newBoardPosition = currentBoardPosition as BoardPosition
                        delete newBoardPosition[square]
                        updatePosition(newBoardPosition as BoardPosition)
                    } else {
                        if (selectModeOn && !deleteModeOn) {
                            let newBoardPosition = currentBoardPosition as BoardPosition
                            newBoardPosition[square] = selectedPiece
                            updatePosition(newBoardPosition as BoardPosition)
                        }
                    }
                } else {
                    if (selectModeOn && !deleteModeOn) {
                        let newBoardPosition = currentBoardPosition as BoardPosition
                        newBoardPosition[square] = selectedPiece
                        updatePosition(newBoardPosition as BoardPosition)
                    }
                }
            }}
            onMouseOverSquare={(square) => {
                // if we are in delete mode, highlight the squares that have pieces on them
                if (deleteModeOn) {
                    let currentPiecesOnBoard = Object.keys(currentBoardPosition)
                    if (currentPiecesOnBoard.includes(square)) {
                        let styles = {}
                        styles[square] = {
                            background: 'rgba(255, 0, 0, 0.7)',
                            transition: 'background-color 250ms ease-in-out',
                        }
                        setSquareStyles(styles)
                    }
                }
                // if we are in select mode and a piece is selected, highlight the squares on hover
                else if (selectModeOn && !deleteModeOn && selectedPiece) {
                    let styles = {}
                    styles[square] = {
                        background: 'rgba(255, 255, 0, 0.7)',
                        transition: 'background-color 250ms ease-in-out',
                    }
                    setSquareStyles(styles)
                }
            }}
            onMouseOutSquare={(square) => {
                let styles = {}
                styles[square] = {
                    background: 'rgba(0, 0, 0, 0)',
                    transition: 'background-color 250ms ease-in-out',
                }
                setSquareStyles(styles)
            }}
            onDragOverSquare={(square) => {
                let styles = {}
                styles[square] = {
                    background: 'rgba(255, 255, 0, 0.7)',
                    transition: 'background-color 250ms ease-in-out',
                }
                setSquareStyles(styles)
            }}
            // pieces are draggable only if we are not in delete mode or select mode
            arePiecesDraggable={!deleteModeOn && !selectModeOn}
        />
    )
}
