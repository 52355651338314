import { Box, Typography } from '@mui/material'
import { RatingCounter } from '../../../../../../components/Rating/RatingCounter'
import { FramedAvatar } from '../../../../../../sharedComponents/src/globalHeader/components/FramedAvatar'
import { useStoreState } from '../../../../../../store/hooks'
import { PlayerInGameData, TimeMode } from '../../../../../../store/types'
import { GameOverColumnContent } from '../GameOverColumnContent'
import { RatingChange } from '../RatingChange/RatingChange'

type Props = {
    playerInfo: PlayerInGameData
    winner: boolean
    rated: boolean
    oldRating: number
    newRating: number
    timeMode: TimeMode
}

export const PlayerColumn = ({ playerInfo, winner, rated, oldRating, newRating, timeMode }: Props) => {
    const { avatarUrl, playerKind, userName, ratings, reward } = playerInfo
    const settings = useStoreState((state) => state.gameView.settings)

    return (
        <GameOverColumnContent winner={winner}>
            <FramedAvatar
                avatar={avatarUrl}
                season={reward?.season}
                reward={reward?.tier}
                sx={{ maxWidth: '140px', width: '100%', height: 'auto', aspectRatio: '1', margin: '0 auto 0.75rem' }}
            />
            <Typography
                variant="body2"
                sx={{ pt: 1, textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
                {userName}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pt: 2,
                    pb: 1,
                }}
            >
                {!settings.friendlyMode &&
                    (rated ? (
                        <div
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px 0' }}
                        >
                            <RatingCounter
                                oldRating={oldRating}
                                newRating={newRating}
                                type={timeMode.name}
                                isProvisional={ratings[timeMode.name].is_provisional}
                            />
                            <RatingChange change={newRating > 0 ? newRating - oldRating : 0} />
                        </div>
                    ) : (
                        <Typography
                            color="secondary"
                            fontFamily="Space Grotesk"
                            style={{ fontSize: '24px', textTransform: 'capitalize' }}
                        >
                            Unrated
                        </Typography>
                    ))}
            </Box>
        </GameOverColumnContent>
    )
}
