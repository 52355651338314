import { Box, Typography } from '@mui/material'
import React from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { Countdown } from './Countdown'
import { DailyResult } from './DailyResult'

export const DailyCooldown = () => {
    const dayProgress = useStoreState((state) => state.puzzleQuestView.dayProgress)
    const getPuzzle = useStoreActions((actions) => actions.puzzleQuestView.getPuzzle)
    const title = dayProgress.reward ? 'Great Job!' : 'Keep Trying!'

    const containerRef = React.useRef<HTMLElement>(null)

    return (
        <Box
            ref={containerRef}
            // py="60px"
            sx={{
                padding: '0 !important',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                height: '100%',
                gap: 1,
                overflow: 'hidden',
            }}
        >
            <Box>
                <Typography textAlign="center" color="secondary" variant="h3" paragraph>
                    {title}
                </Typography>
                <Typography textAlign="center" variant="h5">
                    You earned {dayProgress.reward} crown{dayProgress.reward === 1 ? '' : 's'} today!
                </Typography>
            </Box>

            <DailyResult />
            <Box display="flex" flexDirection="column" alignItems="center" flexGrow={{ xs: 1, md: 'unset' }}>
                <Typography textAlign="center" variant="body2" paragraph>
                    New challenges available in:
                </Typography>

                <Countdown
                    endTime={dayProgress.endTime}
                    callback={getPuzzle}
                    sx={{
                        textAlign: 'center',
                        fontSize: '24px',
                        '> span': {
                            mx: 0.5,
                        },
                    }}
                />
            </Box>
        </Box>
    )
}
