import { Box, Grid, Typography } from '@mui/material'
import { useStoreState } from '../../../store/hooks'
import { DailyCooldown } from '../components/DailyCooldown'
import { DailyProgress } from '../components/DailyProgress'
import { PuzzleHeader } from '../components/PuzzleHeader'
import { PuzzleQuestControls } from '../components/PuzzleQuestControls'
import { PuzzleQuestNavigation } from '../components/PuzzleQuestNavigation'
import { StatusHeader } from '../components/StatusHeader'
import { WeeklyProgress } from '../components/WeeklyProgress'
import { WeeklyProgressHeader } from '../components/WeeklyProgressHeader'

const DesktopWeeklyProgress = () => {
    return (
        <>
            <Typography
                sx={{ borderTop: '2px solid #252636' }}
                pt={1}
                mx={1}
                fontSize="24px"
                variant="h6"
                textTransform="none"
                fontWeight="400"
            >
                Event Ends In:
            </Typography>
            <Box display="flex" justifyContent="space-between" mx={1} fontSize="20px">
                <WeeklyProgressHeader />
            </Box>
            <WeeklyProgress />
        </>
    )
}

export const DesktopPQ = () => {
    const dayProgress = useStoreState((state) => state.puzzleQuestView.dayProgress)

    return (
        <>
            <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    flexWrap: 'nowrap',
                    minHeight: '350px',
                    border: `1px solid`,
                    backgroundColor: 'background.paper',
                    overflow: 'auto',
                    justifyContent: 'space-between',
                    paddingY: 2.5,
                    '> *:not(:last-child)': { paddingX: 2.5 },
                    '> :last-child': { mx: 2.5 },
                    gap: 2,
                }}
            >
                {dayProgress.completed ? (
                    <DailyCooldown />
                ) : (
                    <>
                        <PuzzleHeader />
                        <Box
                            sx={{
                                maxHeight: '80px',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: '2px solid #252636',
                                borderTop: '2px solid #252636',
                            }}
                        >
                            <StatusHeader />
                        </Box>
                        <DailyProgress />
                    </>
                )}
                <DesktopWeeklyProgress />
            </Grid>
            {!dayProgress.completed ? <PuzzleQuestControls /> : <PuzzleQuestNavigation />}
        </>
    )
}
