import { SocialType } from '../../SocialLogin/SocialLogin'
import { FTUE, Rules, Settings, UserData, UserGroup } from '../GlobalHeader'
import GetCookie from '../common/getCookie'
import removeCookie from '../common/removeCookie'
import { ValidationState } from './../forms/types'

// -------------------------------------------------------------KEYS-------------------------------------------------------------
const sessionStorageKey = '_icc_user'
export const tokenCookieKey = 'jwt'

// -------------------------------------------------------------HELPER FUNCTIONS-------------------------------------------------------------
function getTokenExpirationDate(token: string, apiUrl: string) {
    try {
        const payloadBase64 = token.split('.')[1]
        const payload = JSON.parse(atob(payloadBase64))
        const expirationTimestamp = payload.exp
        const expirationDate = new Date(expirationTimestamp * 1000)
        return expirationDate
    } catch (e) {
        removeCookie(tokenCookieKey, apiUrl)
        return new Date()
    }
}

export const getCookieDomain = (apiURL: string) => {
    if (apiURL.endsWith('.iccstage.net')) return '.iccstage.net'
    if (apiURL.endsWith('.chessnclub.net')) return '.chessnclub.net'
    return '.chessclub.com'
}

// -------------------------------------------------------------LOGIN FLOW-------------------------------------------------------------
export const login = async (
    identifier: string,
    password: string,
    apiURL: string,
    isGuest: boolean = false,
    logoutHandler: () => Promise<void>,
) => {
    try {
        const response = await fetch(`${apiURL}/login`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            body: JSON.stringify({ identifier, password }),
            headers: { 'Content-Type': 'application/json' },
        })

        const data = await response.json()

        if (response.status === 412 && !data.confirmed) {
            // handle email confirmation required
            return { ...data, isError: true }
        }

        if (!response.ok) {
            throw new Error(data.message || 'Something went wrong with the login!')
        }
        //  unnecessary removes token while it's already overridden
        // if (isGuest) {
        // await logoutHandler()
        // }
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(data))

        return data
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const renewToken = async (token: string, apiURL: string, forced?: boolean) => {
    if (!token || !navigator.onLine) return token
    const expirationDate = getTokenExpirationDate(token, apiURL)
    const expirationTimeLeft = expirationDate.getTime() - new Date().getTime()
    const refreshTokenInterval = 1000 * 60 * 60 * 168 // 1 week
    if (expirationTimeLeft < 0) {
        sessionStorage.setItem(sessionStorageKey, '')
        removeCookie(tokenCookieKey, getCookieDomain(apiURL))
        return undefined
    }

    if (expirationTimeLeft < refreshTokenInterval || forced) {
        try {
            const response = await fetch(`${apiURL}/api/user/renew`, {
                method: 'PUT',
                mode: 'cors',
                credentials: 'include',
            })

            const data = await response.json()

            if (!response.ok || !data.jwt) {
                throw new Error(data.message || 'Something went wrong verifying the token!')
            }

            return data.jwt
        } catch (error) {
            sessionStorage.setItem(sessionStorageKey, '')
            removeCookie(tokenCookieKey, getCookieDomain(apiURL))
            console.log('renew token error', error)
            return undefined
        }
    }
    if (forced) {
        window?.location?.reload()
        return undefined
    }
}

export const ensureLogin = async (apiURL: string) => {
    // check for cookie and session storage if no, nothing happens
    let token = GetCookie(tokenCookieKey)
    await renewToken(token, apiURL)
    if (token) {
        try {
            const user = await loadUserProfileData(apiURL)

            if (user && !user.error) {
                sessionStorage.setItem(sessionStorageKey, JSON.stringify({ user }))

                return user
            } else {
                sessionStorage.setItem(sessionStorageKey, '')
                removeCookie(tokenCookieKey)
                return null
            }
        } catch (error) {
            console.log(error)
        }
    } else {
        return null
    }
}

export const logout = async (apiURL: string) => {
    sessionStorage.setItem(sessionStorageKey, '')
    removeCookie(tokenCookieKey, getCookieDomain(apiURL))
    //     // TODO: not implemented on backend
    // try {
    // sessionStorage.setItem(sessionStorageKey, '')
    // setCookie(tokenCookieKey, '', getCookieDomain(apiURL))
    //     const response = await fetch(`${apiURL}/logout`, {
    //         method: 'GET',
    //         mode: 'cors',
    //         headers: { 'Content-Type': 'application/json' },
    //         credentials: 'include',
    //     })

    //     const data = await response.json()

    //     if (!response.ok) {
    //         throw new Error(data.message || 'Something went wrong with the logout!')
    //     } else {
    //         sessionStorage.setItem(sessionStorageKey, '')
    //         removeCookie(tokenCookieKey)
    //         return data
    //     }
    // } catch (error) {
    //     return { error: error instanceof Error ? error.message : error }
    // }
}

export const SECOND_STEP = 'go to second step'

export const loginSocial = async (
    apiURL: string,
    socialToken: string,
    accessToken: string,
    socialType: SocialType,
    userName?: string,
    country?: string,
    over12?: boolean,
    email?: string,
    firstName?: string,
    lastName?: string,
) => {
    try {
        const response = await fetch(`${apiURL}/loginSocial`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify({
                social_token: socialToken,
                access_token: accessToken,
                social_network_type: socialType,
                email: email,
                user_name: userName,
                first_name: firstName,
                last_name: lastName,
                over_12_years_old: over12,
                country: country,
            }),
            headers: { 'Content-Type': 'application/json' },
        })

        const data = await response.json()

        if (!response.ok) {
            if (data.errors?.code === 1119) {
                if (socialType === SocialType.APPLE) {
                    const error = {
                        message: SECOND_STEP,
                        socialToken: data.errors?.social_token,
                    }
                    throw new Error(JSON.stringify(error))
                } else {
                    throw new Error(SECOND_STEP)
                }
            } else {
                throw new Error(data.message || 'Something went wrong with the login!')
            }
        }

        sessionStorage.setItem(sessionStorageKey, JSON.stringify(data))

        return data
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

// -------------------------------------------------------------REGISTRATION FLOW-------------------------------------------------------------
export const signup = async (
    email: string,
    password: string,
    user_name: string,
    country: string,
    over12: boolean,
    apiURL: string,
) => {
    try {
        const currentURL = window.location.href
        const successRedirectURL = currentURL
        const failureRedirectURL = `${currentURL}${currentURL.indexOf('?') > -1 ? '&' : '?'}emailConfirmed=false`

        const response = await fetch(`${apiURL}/register`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                email,
                password,
                user_name,
                country,
                over_12_years_old: over12,
                successRedirectURL,
                failureRedirectURL,
            }),
            headers: { 'Content-Type': 'application/json' },
        })

        const data = await response.json()

        if (!response.ok) {
            throw new Error(data.message || 'Something went wrong creating the account!')
        }

        return data
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const guestRegistration = async (apiURL: string) => {
    try {
        const response = await fetch(`${apiURL}/registerGuest`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
        })

        const data = await response.json()

        if (!response.ok) {
            throw new Error(data.message || 'Something went wrong creating the guest account!')
        }

        sessionStorage.setItem(sessionStorageKey, JSON.stringify(data))
        return data
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const upgradeGuest = async (
    email: string,
    password: string,
    user_name: string,
    country: string,
    over12: boolean,
    apiURL: string,
) => {
    try {
        const currentURL = window.location.href
        const successRedirectURL = currentURL
        const failureRedirectURL = `${currentURL}${currentURL.indexOf('?') > -1 ? '&' : '?'}emailConfirmed=false`

        const response = await fetch(`${apiURL}/api/user/upgradeGuest`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                email,
                password,
                user_name,
                country,
                over_12_years_old: over12,
                successRedirectURL,
                failureRedirectURL,
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        })

        const data = await response.json()

        if (!response.ok) {
            throw new Error(data.message || 'Something went wrong creating the account!')
        }

        logout(apiURL)

        return data
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const upgradeGuestSocial = async (
    apiURL: string,
    socialToken: string,
    accessToken: string,
    socialType: SocialType,
    logoutHandler: () => Promise<void>,
    userName?: string,
    country?: string,
    over12?: boolean,
) => {
    try {
        const response = await fetch(`${apiURL}/api/user/upgradeGuestSocial`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify({
                social_token: socialToken,
                access_token: accessToken,
                social_network_type: socialType,
                user_name: userName,
                country,
                over_12_years_old: over12,
            }),
            headers: { 'Content-Type': 'application/json' },
        })

        const data = await response.json()

        if (!response.ok) {
            if (data.errors?.code === 1119) {
                if (socialType === SocialType.APPLE) {
                    const error = {
                        message: SECOND_STEP,
                        socialToken: data.errors?.social_token,
                    }
                    throw new Error(JSON.stringify(error))
                } else {
                    throw new Error(SECOND_STEP)
                }
            } else {
                throw new Error(data.message || 'Something went wrong creating the account!')
            }
        }

        // unnecessary removes token while it's already overridden
        // await logoutHandler()

        sessionStorage.setItem(sessionStorageKey, JSON.stringify(data))

        return data
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const isEmailTaken = async (email: string, apiURL: string, callback: (taken: boolean) => void) => {
    const encodedEmail = encodeURIComponent(email)
    try {
        const response = await fetch(`${apiURL}/isEmailTaken?email=${encodedEmail}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (!response.ok) {
            callback(false)
            return
        }

        const isTaken = !!data && data.taken
        callback(isTaken)
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const validateUsername = async (
    userName: string,
    apiURL: string,
    callback: (result: ValidationState) => void,
) => {
    try {
        const response = await fetch(`${apiURL}/usernameTaken?userName=${userName}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (data.taken) {
            callback(ValidationState.TAKEN)
            return
        }
        if (data.message === 'username is not allowed') {
            callback(ValidationState.NOT_ALLOWED)
            return
        }

        if (!data) {
            callback(ValidationState.ERROR)
            return
        }

        callback(ValidationState.FREE)
    } catch (error) {
        callback(ValidationState.ERROR)
        return { error: error instanceof Error ? error.message : error }
    }
}

export const forgotPassword = async (email: string, apiURL: string) => {
    try {
        const response = await fetch(`${apiURL}/newPasswordEmail`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ email: email }),
            headers: { 'Content-Type': 'application/json' },
        })

        const data = await response.json()

        if (!response.ok) {
            throw new Error(data.message || 'Something went wrong!')
        }

        return data
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const resetPassword = async (token: string, password: string, apiURL: string) => {
    try {
        const response = await fetch(`${apiURL}/resetPassword`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ reset_token: token, new_password: password }),
            headers: { 'Content-Type': 'application/json' },
        })

        const data = await response.json()

        if (!response.ok) {
            throw new Error(data.message || 'Something went wrong while resetting the password!')
        }

        return data
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const changePassword = async (currentPassword: string, newPassword: string, token: string) => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_AMS_URL}/api/user/password`, {
        method: 'PUT',
        body: JSON.stringify({
            current_password: currentPassword,
            new_password: newPassword,
        }),
        headers: {
            'Content-Type': 'application/json',
            authorization: 'Bearer ' + token,
        },
    })

    const data = await response.json()

    return data
}

export const resendConfirmationEmail = async (email: string, apiURL: string) => {
    const currentURL = window.location.origin + window.location.pathname
    const successRedirectURL = encodeURIComponent(currentURL)
    const failureRedirectURL = encodeURIComponent(`${currentURL}?emailConfirmed=false`)

    const encodedEmail = encodeURIComponent(email)
    const response = await fetch(
        `${apiURL}/resendConfirmationEmail?email=${encodedEmail}&successRedirectURL=${successRedirectURL}&failureRedirectURL=${failureRedirectURL}`,
        {
            method: 'GET',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
        },
    )

    return response

    // const data = await response.json()

    // if (!response.ok) {
    //     throw new Error(data.message || 'Something went wrong with resending the email!')
    // }

    // return data
}

// -------------------------------------------------------------USER PROFILE-------------------------------------------------------------
export const getUserData = () => {
    if (typeof window === 'undefined') return null
    const userData = sessionStorage.getItem(sessionStorageKey)
    if (!!userData) {
        const parsedData = JSON.parse(userData)
        if (parsedData.error) {
            return null
        }
        return parsedData
    }
    return null
}

export const loadUserProfileData = async (apiURL: string) => {
    try {
        const response = await fetch(`${apiURL}/api/user/profile`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            // headers: { 'Content-Type': 'application/json' },
        })

        const data = await response.json()

        if (!response.ok) {
            throw new Error(data.message || 'Something went wrong loading the user profile data')
        }
        //sessionStorage.setItem(sessionStorageKey, JSON.stringify(data))
        return data
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const updateSessionSettings = (settings: Settings) => {
    const userData = sessionStorage.getItem(sessionStorageKey)
    if (!!userData) {
        const parsedData = JSON.parse(userData)
        parsedData.user.game_settings = settings
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(parsedData))
    }
}

export const updateSessionUser = (user: UserData) => {
    const userData = sessionStorage.getItem(sessionStorageKey)
    if (!!userData) {
        const parsedData = JSON.parse(userData)
        parsedData.user = user
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(parsedData))
    }
}

export const saveUserSettings = async (apiURL: string, token: string, settings: Settings) => {
    try {
        const response = await fetch(`${apiURL}/api/user/settings`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(settings),
        })

        const data = await response.json()
        if (!response.ok) {
            throw new Error(data.message || 'Something went wrong with saving user settings')
        }

        updateSessionSettings(settings)
        return data
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const editProfile = async (userData: UserData, token: string) => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_AMS_URL}/api/user/profile`, {
        method: 'PUT',
        body: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
            authorization: 'Bearer ' + token,
        },
    })

    const data = await response.json()

    return data
}

export const updateFTUE = async (ftue: Partial<Record<FTUE, boolean>>, apiURL: string, token: string) => {
    const response = await fetch(`${apiURL}/api/user/ftue`, {
        method: 'PATCH',
        body: JSON.stringify(ftue),
        headers: {
            'Content-Type': 'application/json',
            authorization: 'Bearer ' + token,
        },
    })

    const data = await response.json()

    return data
}

export const getUserAccess = (accessRule: Rules) => {
    let isEnabled = false
    const data = getUserData()
    const groups: Array<UserGroup> = data?.user?.groups || null
    if (groups) {
        const ruleNames = groups.flatMap((group) => group.rules.map((rule) => rule.name))
        isEnabled = ruleNames?.some((rule) => rule === Rules.FULL_ACCESS || rule === accessRule) || false
    }

    return isEnabled
}

export const createQueryParams = (queryObject: { [key: string]: any }) => {
    const queryArray: any[] = []

    Object.keys(queryObject).forEach((key) => {
        if (
            queryObject[key] === undefined ||
            queryObject[key] === null ||
            queryObject[key] === '' ||
            (Array.isArray(queryObject[key]) && queryObject[key].length === 0)
        )
            return

        if (Array.isArray(queryObject[key])) {
            queryObject[key].forEach((el: any) => {
                queryArray.push(`${key}=${el}`)
            })
        } else {
            queryArray.push(`${key}=${queryObject[key]}`)
        }
    })

    return queryArray.length === 0 ? '' : '?' + queryArray.join('&')
}

type UserRating = {
    rating: number
    win: number
    draw: number
    loss: number
    best?: number
    game_type: 'bullet' | 'blitz' | 'rapid' | 'classic'
    best_date?: Date
    total_games: number
    is_provisional: boolean
}

export type UserSearchResult = {
    avatarUrl: string
    country: string
    ratings: {
        blitz: UserRating
        bullet: UserRating
        rapid: UserRating
        classic: UserRating
    }
    title: string
    id: string
    userName: string
    status: 'online' | 'offline'
    reward: {
        tier: number
        season: string
    }
}

export const getPlayers = async (
    apiURL: string,
    token: string,
    limit: number,
    search: string,
    filter: string,
): Promise<UserSearchResult[] | false> => {
    try {
        const response = await fetch(
            `${apiURL}/api/user/players${createQueryParams({
                limit,
                search,
                filter,
            })}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            },
        )
        const data = await response.json()
        if (!response.ok) return false
        return data.users || false
    } catch (e) {
        return false
    }
}

export interface IAccountDeleteConfig {
    timeHours: number
}

export const getAccountDeleteConfig = async (apiURL: string, token: string): Promise<IAccountDeleteConfig> => {
    try {
        const response = await fetch(`${apiURL}/api/user/getAccountDeleteConfig`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })

        const data = await response.json()

        if (!response.ok) {
            return { timeHours: 0 }
        }

        return !!data && data
    } catch (error) {
        return { timeHours: 0 }
    }
}

export interface IDeleteAccountDemandData {
    email: string
    userName: string
    redirectLink: string
}

export const deleteAccountDemand = async (requestData: IDeleteAccountDemandData, apiURL: string, token: string) => {
    try {
        const response = await fetch(`${apiURL}/api/user/deleteAccountDemand`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })

        const data = await response.json()

        if (!response.ok) {
            throw new Error(data.message || 'Something went wrong while resetting the password!')
        }

        return true
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

const prepareFileToUpload = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = (event: any) => {
            const arrayBuffer = event.target.result
            const byteArray = new Uint8Array(arrayBuffer)
            resolve(Array.from(byteArray))
        }

        reader.onerror = (error) => {
            reject(error)
        }

        reader.readAsArrayBuffer(file)
    })
}

export const uploadAvatar = async (file: File, apiURL: string, token: string) => {
    try {
        const byteArray = await prepareFileToUpload(file)
        const response = await fetch(`${apiURL}/api/user/profile/avatar`, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify({ avatar: byteArray }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })

        const data = await response.json()

        if (!response.ok) {
            throw new Error(data.message || 'Something went wrong while uploading avatar!')
        }

        return true
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}
