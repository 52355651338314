import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew'
import Close from '@mui/icons-material/Close'
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, MobileStepper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import GetCookie from '../../sharedComponents/src/globalHeader/common/getCookie'
import WelcomeGuestForm from '../../sharedComponents/src/globalHeader/forms/WelcomeGuestForm'
import WelcomeUserForm from '../../sharedComponents/src/globalHeader/forms/WelcomeUserForm'
import { saveUserSettings, updateFTUE } from '../../sharedComponents/src/globalHeader/functions/ams-functions'
import { analyticsManager } from '../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { FTUEContent, content } from './content'

export const FTUE = () => {
    const token = GetCookie('jwt')
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)
    const [activeFTUE, setActiveFTUE] = useState<string>('')
    const activeTab = useStoreState((state) => state.activeTab) // Current active tab for matchmaking drawer - 'New game' = 0 | 'Players' = 1 | 'Computer' = 2
    const userData = useStoreState((state) => state.userData)
    const setUserData = useStoreActions((state) => state.setUserData)
    const settings = useStoreState((state) => state.gameView.settings)
    const setSettings = useStoreActions((state) => state.gameView.setSettings)
    const [currentStep, setCurrentStep] = useState<number>(0)
    const currentLocation = window.location.pathname

    useEffect(() => {
        if (userData.userData?.ftue) {
            setCurrentStep(0)

            if (currentLocation === '/') {
                setActiveFTUE(`/${activeTab}`)
            } else {
                setActiveFTUE(currentLocation)
            }
        }
    }, [currentLocation, activeTab, userData.userData?.ftue])

    const activeContent = content[activeFTUE]
    const isLastStep = currentStep === activeContent?.content.length - 1

    const handleClose = (key: FTUEContent['key']) => {
        setActiveFTUE('')
        setCurrentStep(0)
        updateFTUE({ [key]: true }, import.meta.env.VITE_REACT_APP_AMS_URL, token).then(() => {
            if (userData.userData)
                setUserData({
                    ...userData,
                    userData: { ...userData.userData, ftue: { ...userData.userData.ftue, [key]: true } },
                })
        })
    }

    const handleAction = () => {
        setCurrentStep((prev) => {
            if (prev === activeContent?.content.length - 1) {
                handleClose(activeContent.key)
                return 0
            } else {
                return prev + 1
            }
        })
    }

    const onHideRatingHandler = async (value: boolean) => {
        if (userData.userData && token) {
            const updatedSettings = { ...settings, friendlyMode: value }
            setUserData({ ...userData, userData: { ...userData.userData, game_settings: updatedSettings } })
            setSettings(updatedSettings)
            setOverlayTrigger({ name: 'updateSettings', data: updatedSettings })
            try {
                await saveUserSettings(import.meta.env.VITE_REACT_APP_AMS_URL, token, updatedSettings)
            } catch (error) {
                console.error('Error saving user settings:', error)
            }
        }
    }

    if (!userData.userData) return null

    if (!userData.userData.ftue.welcomeGuest && userData.userData.ftue.mode === 'guest')
        return (
            <WelcomeGuestForm
                open={true}
                onClose={() => handleClose('welcomeGuest')}
                userName={userData.userData.user_name}
                onSignUpClick={() => {
                    // handleClose('welcomeGuest')
                    setOverlayTrigger('signup')
                    analyticsManager.dispatchEvent('openSignup', {
                        origin: 'guestWelcome',
                    })
                }}
                onSignin={() => {
                    // handleClose('welcomeGuest')
                    setOverlayTrigger('signin')
                    analyticsManager.dispatchEvent('openLogin', {
                        origin: 'guestWelcome',
                    })
                }}
            />
        )

    if (!userData.userData.ftue.welcomeUser && userData.userData.ftue.mode === 'user')
        return (
            <WelcomeUserForm
                username={userData.userData.user_name}
                hideRating={settings.friendlyMode || false}
                onHideRatingChange={onHideRatingHandler}
                onClose={() => {
                    handleClose('welcomeUser')
                }}
            />
        )

    if (!activeContent || userData.userData?.ftue[activeContent.key]) return null

    return (
        <Dialog
            sx={{
                '.MuiPaper-root': {
                    backgroundColor: 'background.default',
                    maxWidth: '700px',
                    width: '100%',
                    flexDirection: 'column',
                },
            }}
            open={Boolean(activeContent)}
            onClose={() => handleClose(activeContent.key)}
            scroll="paper"
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
                <IconButton
                    size="small"
                    sx={{ svg: { fontSize: '24px' } }}
                    onClick={() => setCurrentStep((prev) => Math.max(prev - 1, 0))}
                    disableRipple
                    disabled={!Boolean(currentStep)}
                >
                    {Boolean(currentStep) && <ArrowBackIosNew />}
                </IconButton>

                <IconButton
                    size="small"
                    sx={{ svg: { fontSize: '34px' } }}
                    onClick={() => handleClose(activeContent.key)}
                    disableRipple
                >
                    <Close />
                </IconButton>
            </Box>
            <DialogContent
                sx={{
                    p: 0,
                    px: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    br: {
                        display: 'block',
                        content: '""',
                        marginTop: 2,
                    },
                }}
                dividers={false}
            >
                <Box
                    sx={{
                        maxWidth: 'min(484px, 100%)',
                        width: '100%',
                        height: 'min(500px, 60vh)',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="h1" color="secondary">
                        {activeContent.content[currentStep].prefix || 'Getting Started'}
                    </Typography>
                    <Typography variant="h1" paragraph>
                        {activeContent.content[currentStep].title}
                    </Typography>
                    <Typography variant="body1">{activeContent.content[currentStep].description}</Typography>
                    <br />
                    <Box
                        width="100%"
                        flexGrow="1"
                        sx={{
                            backgroundImage: `url(${activeContent.content[currentStep].image})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                        {/* PRELOAD NEXT IMAGE */}
                        {!isLastStep && (
                            <img
                                src={activeContent.content[currentStep + 1].image}
                                alt={activeContent.content[currentStep + 1].title.toString()}
                                style={{ display: 'none' }}
                            />
                        )}
                    </Box>
                </Box>
            </DialogContent>
            {activeContent?.content.length > 1 && (
                <MobileStepper
                    sx={{ mx: 'auto', justifyContent: 'center' }}
                    variant="dots"
                    steps={activeContent?.content.length}
                    position="static"
                    activeStep={currentStep}
                    backButton={undefined}
                    nextButton={undefined}
                />
            )}
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                <Button sx={{ maxWidth: '260px' }} variant="outlined" onClick={handleAction}>
                    {isLastStep ? "Let's start" : 'Next'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
