import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { Box, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import CorrectIcon from '../../../../assets/icons/correct.svg?react'
import HintIcon from '../../../../assets/icons/hint.svg?react'
import IncorrectIcon from '../../../../assets/icons/incorrect.svg?react'
import { Color } from '../../../../chess/types'
import { colors } from '../../../../sharedComponents/src/globalHeader/theme/colors'
import { BOT_MOVING_DELAY } from '../../PuzzlesTrainingViewV2'

const iconStyles = {
    marginRight: '16px',
    height: '24px',
    width: '24px',
}

const headerWrapperStyles = {
    margin: '0 auto',
    display: 'flex',
    textWrap: 'nowrap',
    justifyContent: 'center',
    width: 'fit-content',
    '& .MuiTypography-h5': { height: '2.6rem', padding: '0.4rem 1rem', display: 'flex', alignItems: 'center' },
}
interface IProps {
    puzzleState: boolean | null
    isBotMoving: boolean
    hint: boolean
    usedHint: boolean
    usedAttempts: boolean
    isPuzzleStarted: boolean
    isLastMoveCorrect: boolean
    usedShowSolution: boolean
    myColor: Color
    mode?: 'puzzle' | 'puzzleQuest'
}

enum HeaderStatus {
    PLAYER_MOVE = 'PLAYER_MOVE',
    BOT_MOVE = 'BOT_MOVE',
    CORRECT_MOVE = 'CORRECT_MOVE',
    INCORRECT_MOVE = 'INCORRECT_MOVE',
    HINT = 'HINT',
    SOLUTION = 'SOLUTION',
    SUCCESS = 'SUCCESS',
    ALMOST_SUCCESS = 'ALMOST_SUCCESS',
}

const getCircle = (color) => (
    <span
        style={{
            display: 'inline-block',
            width: '24px',
            height: '24px',
            marginRight: '16px',
            borderRadius: '100px',
            backgroundColor: color,
            border: '1px solid white',
        }}
    />
)

const getHeaderStatusElements: (color: Color, mode: IProps['mode']) => Record<HeaderStatus, ReactJSXElement> = (
    color,
    mode,
) => {
    const side = {
        player: '',
        bot: '',
    }
    if (color === Color.Black) {
        side.player = 'black'
        side.bot = 'white'
    } else {
        side.player = 'white'
        side.bot = 'black'
    }

    return {
        PLAYER_MOVE: (
            <Typography variant="h5">
                {getCircle(side.player)} {side.player} to move
            </Typography>
        ),
        BOT_MOVE: (
            <Typography variant="h5">
                {getCircle(side.player)} {side.player} to move
            </Typography>
            // <Typography color="secondary" variant="h5">
            //     {getCircle(side.bot)} {side.bot} to move
            // </Typography>
        ),
        CORRECT_MOVE: (
            <Typography color="success.dark" variant="h5">
                <CorrectIcon style={iconStyles} />
                correct move
            </Typography>
        ),
        INCORRECT_MOVE: (
            <Typography
                sx={{
                    '> svg > path': {
                        fill: colors.error.dark,
                    },
                }}
                color="error.dark"
                variant="h5"
            >
                <IncorrectIcon style={iconStyles} />
                {mode === 'puzzleQuest' ? 'puzzle failed' : 'incorrect'}
            </Typography>
        ),
        HINT: (
            <Typography color="#DCFB19" variant="h5">
                <HintIcon style={iconStyles} />
                hint
            </Typography>
        ),
        SOLUTION: (
            <Typography color="secondary" variant="h5">
                solution
            </Typography>
        ),
        SUCCESS: (
            <Typography color="#FFB01A" sx={{ svg: { path: { fill: '#FFB01A' } } }} variant="h5">
                <CorrectIcon style={iconStyles} />
                puzzle solved
            </Typography>
        ),
        ALMOST_SUCCESS: (
            <Typography color="#FFB01A" sx={{ svg: { path: { fill: '#FFB01A' } } }} variant="h5">
                <CorrectIcon style={iconStyles} />
                puzzle solved
            </Typography>
            // <Typography color="success.dark" variant="h5">
            //     <CorrectIcon style={iconStyles} />
            //     puzzle solved
            // </Typography>
        ),
    }
}

export const NotationHeader: React.FC<IProps> = ({
    puzzleState,
    isBotMoving,
    hint,
    isPuzzleStarted,
    isLastMoveCorrect,
    usedShowSolution,
    usedHint,
    usedAttempts,
    myColor,
    mode,
}) => {
    const [headerStatus, setHeaderStatus] = useState<HeaderStatus>(HeaderStatus.BOT_MOVE)
    const timeout = useRef<NodeJS.Timeout>()

    // handle return to default state
    useEffect(() => {
        if (headerStatus !== HeaderStatus.SOLUTION) {
            clearTimeout(timeout.current)
            timeout.current = setTimeout(
                () => {
                    if (
                        [HeaderStatus.BOT_MOVE, HeaderStatus.INCORRECT_MOVE, HeaderStatus.HINT].includes(headerStatus)
                    ) {
                        if (mode === 'puzzleQuest' && headerStatus === HeaderStatus.INCORRECT_MOVE) return

                        setHeaderStatus(HeaderStatus.PLAYER_MOVE)
                    }
                },
                headerStatus === HeaderStatus.BOT_MOVE ? BOT_MOVING_DELAY : 2500,
            )
        }
    }, [headerStatus, mode])

    // handle intermediate steps
    useEffect(() => {
        if (isPuzzleStarted) {
            setHeaderStatus(() => {
                if (usedShowSolution) {
                    return HeaderStatus.SOLUTION
                } else if (isLastMoveCorrect === true) {
                    return HeaderStatus.CORRECT_MOVE
                } else if (isBotMoving) {
                    return HeaderStatus.BOT_MOVE
                } else if (hint) {
                    return HeaderStatus.HINT
                } else {
                    return HeaderStatus.PLAYER_MOVE
                }
            })
        }
    }, [usedShowSolution, isBotMoving, hint, isLastMoveCorrect, isPuzzleStarted])

    // handle result
    useEffect(() => {
        if (!usedShowSolution) {
            if (puzzleState !== null) {
                puzzleState
                    ? setHeaderStatus(usedHint || usedAttempts ? HeaderStatus.ALMOST_SUCCESS : HeaderStatus.SUCCESS)
                    : setHeaderStatus(HeaderStatus.INCORRECT_MOVE)
            }
        }
    }, [puzzleState, usedShowSolution, usedHint, usedAttempts])

    // handle reset
    useEffect(() => {
        puzzleState === null && setHeaderStatus(HeaderStatus.PLAYER_MOVE)
    }, [puzzleState])

    return <Box sx={headerWrapperStyles}>{getHeaderStatusElements(myColor, mode)[headerStatus]}</Box>
}
