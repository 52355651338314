import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, CircularProgress, IconButton, Paper, Stack, Typography } from '@mui/material'

import { useCallback, useEffect, useState } from 'react'
import { ChallengeWrapper, RatingType, UserInfo } from '../../../components/matchmaking/ChallengeCard'
import { useStoreState } from '../../../store/hooks'
import { GAME_END_REASONS } from '../../gameView/components/gameServerConnector/GSTypes'

interface HistoryPlayer {
    id: string
    country: string
    userName: string
    rating?: number
    color: 'white' | 'black'
    kind: string
    provisionalProfile: boolean
    title?: string
}

interface GameHistory {
    matchId: string
    matchKind: 'human_vs_ai' | 'human_vs_human'
    player1: HistoryPlayer
    player2: HistoryPlayer
    timeMode: {
        durationMinutes: number
        clockIncrementSeconds: number
        name: RatingType
    }
    outcome: '1-0' | '0-1' | '1/2-1/2' | '*'
    method: string
    pgn: string
    createdAt: string
    startedAt: string
    endedAt: string
    reason: string
    finalFen: string
    rated: boolean
}

interface GameHistoryData {
    matchHistory: Array<GameHistory>
    recordAmount: number
}

export async function getHistory(token: string, limit: number, offset: number): Promise<GameHistoryData | null> {
    const response = await fetch(
        `${
            import.meta.env.VITE_REACT_APP_MM_URL
        }/api/history?gameType=all&limit=${limit}&offset=${offset}&token=${token}`,
        {
            method: 'GET',
        },
    )
    const data = await response.json()
    if (!response.ok) {
        return null
    }
    return data
}

type Props = {
    token: string | undefined
    onGameHistoryClick: (pgn: string, reason: GAME_END_REASONS) => void
}

const MyGamesTab = ({ token, onGameHistoryClick }: Props) => {
    const [gameHistory, setGameHistory] = useState<GameHistory[]>([])
    const [offset, setOffset] = useState(0)
    const [historyLoading, setHistoryLoading] = useState<boolean>(true)
    const [totalCount, setTotalCount] = useState(0)
    const currentUserId = useStoreState((state) => state.userData.userData?.id)

    let limit = 20

    const fetchHistory = useCallback(async () => {
        if (!token) return
        setHistoryLoading(true)
        const data = await getHistory(token, limit, offset)
        if (data) {
            setGameHistory(data.matchHistory)
            setTotalCount(data.recordAmount)
            setTimeout(() => {
                setHistoryLoading(false)
            }, 200)
        }
    }, [token, limit, offset])

    useEffect(() => {
        fetchHistory()
    }, [fetchHistory, token])

    const onShowMoreClick = () => {
        setOffset(offset + limit)
    }

    const onShowLessClick = () => {
        setOffset(offset - limit)
    }

    const result = (color, text: 'lose' | 'win' | 'draw' | 'abort') => (
        <Typography color={color} variant="h6" fontSize="20px">
            {text[0]}
        </Typography>
    )

    const outcomes = {
        black: {
            '1-0': result('error.dark', 'lose'),
            '0-1': result('success.dark', 'win'),
            '1/2-1/2': result('white', 'draw'),
            '*': result('text.disabled', 'abort'),
        },
        white: {
            '1-0': result('success.dark', 'win'),
            '0-1': result('error.dark', 'lose'),
            '1/2-1/2': result('white', 'draw'),
            '*': result('text.disabled', 'abort'),
        },
    }

    return (
        <Paper
            square
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                border: 'solid',
                overflow: 'auto',
                minHeight: '200px',
            }}
        >
            {!!historyLoading ? (
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : gameHistory === undefined || gameHistory.length === 0 ? (
                <Typography
                    variant="h6"
                    sx={{
                        textAlign: 'center',
                        flexGrow: 1,
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    No games found
                </Typography>
            ) : (
                <Box flexGrow={1} overflow="auto">
                    {gameHistory.map((game, index) => {
                        const opponent = game.player1.id === currentUserId ? game.player2 : game.player1
                        const player = game.player1.id === currentUserId ? game.player1 : game.player2
                        return (
                            <ChallengeWrapper
                                key={index}
                                withoutButton
                                game={game}
                                type="history"
                                ratingType={game.timeMode.name as RatingType}
                                handleAction={() => onGameHistoryClick(game.pgn, game.reason as GAME_END_REASONS)}
                            >
                                <Box
                                    display="flex"
                                    flexGrow={1}
                                    flexDirection={'column'}
                                    justifyContent="space-between"
                                >
                                    <UserInfo
                                        type="history"
                                        direction="row"
                                        ratingType={game.timeMode.name}
                                        player={{
                                            ...opponent,
                                            username: opponent.userName,
                                            rating: 0,
                                            is_provisional: false,
                                            nationality: opponent.country || 'WORLD',
                                            title: opponent.title || '',
                                            // MOCK
                                            reward: {
                                                tier: 0,
                                                season: '',
                                            },
                                        }}
                                    />
                                    {game.startedAt && (
                                        <Box display="flex">
                                            <Typography
                                                mr={1}
                                                variant="body2"
                                                sx={{ fontVariantNumeric: 'tabular-nums' }}
                                            >
                                                {new Date(game.startedAt).toLocaleDateString()}
                                            </Typography>
                                            <UserInfo
                                                type="history"
                                                direction="row"
                                                ratingType={game.timeMode.name}
                                                player={{
                                                    ...opponent,
                                                    username: '',
                                                    color: '',
                                                    rating: opponent.rating || 0,
                                                    is_provisional: false,
                                                    title: opponent.title || '',
                                                    nationality: '',
                                                    // MOCK
                                                    reward: {
                                                        tier: 0,
                                                        season: '',
                                                    },
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center" width="32px">
                                    {outcomes[player.color][game.outcome]}
                                </Box>
                            </ChallengeWrapper>
                        )
                    })}
                </Box>
            )}

            <Stack direction="row" justifyContent={'center'} alignItems={'center'} gap={1}>
                <IconButton onClick={onShowLessClick} disabled={offset <= 0}>
                    <ArrowBackIosNewIcon />
                </IconButton>
                <IconButton onClick={onShowMoreClick} disabled={totalCount <= offset + limit}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </Stack>
        </Paper>
    )
}

export default MyGamesTab
