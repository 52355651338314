import { Box, Button, LinearProgress, Typography, alpha } from '@mui/material'
import { FC, ReactElement } from 'react'
import { ChallengeEntry } from '../../store/types'
import { ChallengeVariant, ChallengesProps } from './Challenges'

import CloseIcon from '@mui/icons-material/CloseOutlined'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import ViewIcon from '@mui/icons-material/Visibility'
import ChallengeIcon from '../../assets/icons/challenge.svg?react'
import UnratedIcon from '../../assets/icons/ratings/unrated.svg?react'
import { imgFallback } from '../../helpers/imgFallback'
import { FramedAvatar } from '../../sharedComponents/src/globalHeader/components/FramedAvatar'
import Rating from '../Rating/Rating'
import { Blitz } from '../challengesList/gameTypeIcons/Blitz'
import { Bullet } from '../challengesList/gameTypeIcons/Bullet'
import { Classical } from '../challengesList/gameTypeIcons/Classical'
import { Rapid } from '../challengesList/gameTypeIcons/Rapid'
import { UserTitle } from '../userTitle/UserTitle'

export type RatingType = 'bullet' | 'blitz' | 'rapid' | 'classic'

export const modeIcon = (mode: RatingType) => {
    switch (mode) {
        case 'bullet':
            return <Bullet />
        case 'blitz':
            return <Blitz />
        case 'rapid':
            return <Rapid />
        case 'classic':
            return <Classical />
    }
}

type BasePlayer = Omit<ChallengeEntry['challenger'], 'rating'>

interface ChallengeCardProps extends Omit<ChallengesProps, 'challenges' | 'handleAction' | 'variant'> {
    type: 'challenge' | 'player' | 'view' | 'invite' | 'history'
    variant?: ChallengeVariant
    player: BasePlayer & {
        color?: string
        rating: number | string
        is_provisional: boolean
        reward: {
            tier: number
            season: string
        }
    }
    handleAction: React.MouseEventHandler<HTMLDivElement>
    challenge?: ChallengeEntry
    ratingType: RatingType
    avatarWrapper?: (avatar: ReactElement) => React.ReactNode
    isOnline?: boolean
    actionDisabled?: boolean
}

type SimplifiedChallenge = Omit<ChallengeEntry, 'challengeId' | 'challengeType' | 'challengerColor' | 'challenger'>

interface ChallengeInfo extends ChallengeCardProps {
    game: SimplifiedChallenge | undefined
}

export const ChallengeInfo: FC<Pick<ChallengeInfo, 'game' | 'ratingType' | 'variant'>> = ({
    game,
    ratingType,
    variant,
}) => {
    if (game)
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '64px',
                }}
            >
                <Typography
                    variant="body1"
                    sx={(theme) => ({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: '1',
                        zIndex: 100,
                        '& svg': {
                            fill: theme.palette.primary.main,
                            width: '18px',
                            height: '100%',
                        },
                    })}
                >
                    {modeIcon(ratingType)}
                </Typography>
                <Typography
                    zIndex={100}
                    textAlign="center"
                    noWrap
                    variant="body2"
                    fontFamily="Space Grotesk"
                    fontWeight={700}
                    className={'time'}
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                    }}
                >
                    {`${game.timeMode.durationMinutes}+${game.timeMode.clockIncrementSeconds}`}
                </Typography>
            </Box>
        )
}

export const UserInfo: FC<
    Pick<ChallengeCardProps, 'player' | 'ratingType' | 'type'> & { direction?: 'row' | 'column' }
> = ({ player, ratingType, direction = 'column', type }) => {
    // @ts-ignore
    const playerColor = player.playerColor || player.color
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: direction === 'row' ? 'row-reverse' : 'column',
                justifyContent: direction === 'row' ? 'flex-end' : 'space-between',
                alignItems: direction === 'row' ? 'center' : 'flex-start',
                flexGrow: player.rating || player.username ? 1 : 'unset',
                minWidth: player.rating || player.username ? '10px' : 'unset',
                width: direction === 'row' ? '100%' : 'unset',
            }}
        >
            <Typography noWrap variant="body2">
                {player.username}
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    direction: 'row',
                    alignItems: 'center',
                    minWidth: '50px',
                    'div + div': {
                        mx: 1,
                    },
                }}
            >
                {player.nationality && (
                    <img
                        width="20px"
                        style={{ marginRight: '8px' }}
                        src={`/flags/${player.nationality?.toLocaleUpperCase()}@2x.png`}
                        onError={imgFallback}
                        alt={player.nationality}
                    />
                )}
                {Boolean(player.rating) && (
                    <Rating data={player.rating} type={ratingType} isProvisional={player.is_provisional} />
                )}
                {player.title && <UserTitle title={player.title} />}
                {playerColor && type !== 'player' ? (
                    <Box
                        sx={(theme) => ({
                            width: '14px',
                            height: '14px',
                            background:
                                playerColor === 'no_color'
                                    ? 'linear-gradient(to right, black 50%, white 50%)'
                                    : playerColor,
                            borderRadius: '100px',
                            border: `1px solid ${theme.palette.text.primary} !important`,
                        })}
                    />
                ) : null}
            </Box>
        </Box>
    )
}

export const ChallengeWrapper: FC<
    Pick<ChallengeInfo, 'actionDisabled' | 'handleAction' | 'type' | 'game' | 'ratingType' | 'variant'> & {
        children: React.ReactNode
        withoutButton?: boolean
    }
> = ({ children, actionDisabled, handleAction, type, game, ratingType, withoutButton, variant }) => {
    const actionInfo: Record<typeof type, { label: string; icon: React.ReactNode }> = {
        view: {
            label: 'watch',
            icon: (
                <ViewIcon
                    style={{
                        width: '30px',
                        height: '30px',
                        padding: '5px',
                        flexShrink: 0,
                    }}
                />
            ),
        },
        player: {
            label: 'challenge',
            icon: (
                <ChallengeIcon
                    style={{
                        width: '30px',
                        height: '30px',
                        padding: '5px',
                        flexShrink: 0,
                    }}
                />
            ),
        },
        challenge: {
            label: 'join',
            icon: (
                <ChallengeIcon
                    style={{
                        width: '30px',
                        height: '30px',
                        padding: '5px',
                        flexShrink: 0,
                    }}
                />
            ),
        },
        invite: {
            label: 'cancel',
            icon: <CloseIcon sx={{ width: '24px', height: '24px', marginLeft: '5px !important', fill: 'white' }} />,
        },
        history: {
            label: 'analyze',
            icon: <TroubleshootIcon sx={{ width: '24px', height: '24px', marginLeft: '5px !important' }} />,
        },
    }

    return (
        <Box
            onClick={!actionDisabled ? handleAction : () => {}}
            sx={(theme) => ({
                zIndex: 10,
                position: 'relative',
                cursor: 'pointer',
                transition: 'all 250ms !important',
                display: 'flex',
                gap: '10px',
                flexGrow: 1,
                // justifyContent: 'space-between',
                borderBottom: `2px solid transparent`,
                padding: { xs: '4px', md: '8px 8px' },
                '&:hover': {
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                    backgroundColor: `${alpha(theme.palette.primary.main, 0.08)} !important`,
                    button: {
                        color: `${
                            type === 'invite' ? theme.palette.error.light : theme.palette.primary.main
                        } !important`,
                        svg: {
                            margin: 0,
                            path: {
                                fill:
                                    type === 'invite'
                                        ? `${theme.palette.error.light} !important`
                                        : `${theme.palette.primary.main} !important`,
                            },
                        },
                    },
                },
            })}
        >
            {game && <ChallengeInfo variant={variant} game={game} ratingType={ratingType} />}
            {children}
            {!actionDisabled && !withoutButton ? (
                <Button
                    sx={(theme) => ({
                        transition: 'color 250ms',
                        height: '40px !important',
                        minWidth: 'unset',
                        opacity: '1 !important',
                        color: {
                            // action label hidden on desktop
                            xs: `${theme.palette.text.primary} !important`,
                            md: 'transparent !important',
                        },
                        fontSize: '16px !important',
                        padding: '0px !important',
                        'span, svg': {
                            path: { transition: 'fill 250ms', fill: `${theme.palette.text.primary} !important` },
                            margin: '0px',
                        },
                    })}
                    variant="text"
                    size="small"
                    disableRipple
                    disabled
                    endIcon={actionInfo[type].icon}
                >
                    {actionInfo[type].label}
                </Button>
            ) : null}
        </Box>
    )
}

export const ChallengeCard: FC<ChallengeCardProps> = ({
    avatarWrapper,
    handleAction,
    variant,
    player,
    challenge,
    ratingType,
    type,
    actionDisabled,
    isOnline,
}) => {
    return (
        <Box position="relative" mb="2px">
            <ChallengeWrapper
                variant={variant}
                actionDisabled={actionDisabled}
                handleAction={handleAction}
                type={type}
                game={challenge}
                ratingType={ratingType}
            >
                {avatarWrapper ? (
                    avatarWrapper(
                        <FramedAvatar
                            isOnline={isOnline}
                            season={player.reward?.season}
                            reward={player.reward?.tier}
                            sx={{ height: '40px', width: '40px', zIndex: 20 }}
                            avatar={player.avatarUrl || ''}
                        />,
                    )
                ) : (
                    <FramedAvatar
                        isOnline={isOnline}
                        season={player.reward?.season}
                        reward={player.reward?.tier}
                        sx={{ height: '40px', width: '40px' }}
                        avatar={player.avatarUrl || ''}
                    />
                )}

                <UserInfo player={player} ratingType={ratingType} type={type} />

                {challenge && !challenge.rated && (
                    <Box
                        flexGrow={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={(theme) => ({
                            svg: {
                                path: { fill: theme.palette.primary.main },
                                line: { stroke: theme.palette.primary.main },
                            },
                        })}
                    >
                        <UnratedIcon
                            style={{
                                width: '16x',
                                height: '16px',
                            }}
                        />
                    </Box>
                )}
            </ChallengeWrapper>
            {variant === ChallengeVariant.MY_CHALLENGES && (
                <LinearProgress
                    sx={{
                        opacity: '0.6',
                        display: 'block',
                        position: 'absolute',
                        height: 'auto',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 1,
                    }}
                />
            )}
        </Box>
    )
}
