import { Box, Typography } from '@mui/material'
import { Color, GameTreePosition } from '../chess/types'
import { GameViewState } from '../features/gameView/GameViewModel'
import { MaterialBalance } from '../features/gameView/components/materialBalance/MaterialBalance'
import { PlayerInfo } from '../functions/getPlayerInfos'
import { IOverallRecord } from '../functions/getPlayersStats'
import { imgFallback } from '../helpers/imgFallback'
import { FramedAvatar } from '../sharedComponents/src/globalHeader/components/FramedAvatar'
import { RatingsObject } from '../store/types'
import ProfileInfo from './ProfileInfo/ProfileInfo'
import Rating from './Rating/Rating'
import { UserTitle } from './userTitle/UserTitle'

const avatarStyle = {
    height: '56px',
    width: '56px',
}

const ratingIconStyles = {
    width: '22px',
    height: '22px',
}

export enum PageView {
    GAME = 'game',
    OBSERVE = 'observe',
    ANALYSIS = 'analysis',
}

interface PlayerInfoBarProps {
    player: PlayerInfo | undefined
    gameState: GameViewState | undefined
    gtpos: GameTreePosition | undefined
    clock: JSX.Element | undefined
    color: Color
    view: PageView
    latency?: number
    top: boolean
    overallRecord?: IOverallRecord
    totalScore?: number
    totalScoreColor?: string
    showRating?: boolean
}

export const PlayerInfoBar = (props: PlayerInfoBarProps) => {
    const { player, gameState, gtpos, clock, color, top, overallRecord, view, showRating } = props

    const defaultRatings: RatingsObject = {
        blitz: {
            rating: 0,
            win: 0,
            draw: 0,
            loss: 0,
            game_type: 'blitz',
            total_games: 0,
            is_provisional: false,
        },
        bullet: {
            rating: 0,
            win: 0,
            draw: 0,
            loss: 0,
            game_type: 'bullet',
            total_games: 0,
            is_provisional: false,
        },
        rapid: {
            rating: 0,
            win: 0,
            draw: 0,
            loss: 0,
            game_type: 'rapid',
            total_games: 0,
            is_provisional: false,
        },
        classic: {
            rating: 0,
            win: 0,
            draw: 0,
            loss: 0,
            game_type: 'classic',
            total_games: 0,
            is_provisional: false,
        },
    }

    // ------------- Subcomponents -------------

    const materialBalance = gameState !== GameViewState.PRE_GAME && gtpos && (
        <MaterialBalance pieces={gtpos.position.board} color={color} size={'22px'} />
    )

    const progress = (
        <Box flexGrow={1} flexDirection={top ? 'column' : 'column-reverse'} display="flex" justifyContent="flex-end">
            {clock}
            <Box overflow="visible">{materialBalance}</Box>
        </Box>
    )

    const playerInfo = player ? (
        <ProfileInfo
            user={{
                avatarUrl: player.avatarUrl || '',
                country: player.nationality || '',
                ratings: player.ratings || defaultRatings,
                title: player.title || '',
                id: player.playerId || '',
                userName: player.name || '',
                status: 'online',
                reward: {
                    tier: player.reward?.tier || 0,
                    season: player.reward?.season || '',
                },
            }}
            origin={top ? 'bottom' : 'top'}
            showOnClick={view === PageView.OBSERVE ? true : false}
            showOnHover={player.human || false}
            overallRecord={overallRecord}
            sx={{ display: 'flex', alignItems: 'stretch', gap: '16px', minWidth: '100px' }}
        >
            <Box display="flex" flexDirection="column" minWidth={'50px'}>
                <Typography
                    sx={{
                        flexGrow: 1,
                    }}
                    variant="body1"
                    noWrap
                    textAlign="end"
                >
                    {player ? player.name : ''}
                </Typography>

                <Box
                    sx={{
                        height: '24px',
                        gap: '8px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        // overflow: 'hidden',
                    }}
                >
                    {showRating &&
                        player &&
                        player.human !== false &&
                        player.rating !== undefined &&
                        !Number.isNaN(player.rating) &&
                        Boolean(player.rating) && (
                            <>
                                {player.title && <UserTitle title={player.title} />}
                                {player.ratings && player.ratingType && (
                                    <Rating
                                        data={player.ratings[player.ratingType]}
                                        textSize="body1"
                                        iconSize="medium"
                                    />
                                )}
                            </>
                        )}
                    {player ? (
                        <img
                            src={`/flags/${player.nationality || 'WORLD'}@2x.png`}
                            onError={imgFallback}
                            alt={'flag icon'}
                            style={{
                                height: '22px',
                                borderRadius: '.25rem',
                            }}
                        />
                    ) : null}
                </Box>
            </Box>
            {view === PageView.ANALYSIS && !player.avatarUrl ? (
                <div
                    style={{
                        height: '56px',
                        width: '56px',
                        border: '0.063rem solid white',
                        backgroundColor: color === Color.White ? 'white' : 'black',
                    }}
                />
            ) : (
                <FramedAvatar
                    avatar={player.avatarUrl || ''}
                    season={player.reward?.season}
                    reward={player.reward?.tier}
                    sx={avatarStyle}
                />
            )}
            {/* {gameState === GameViewState.GAME_RUNNING && player && latency && (
                <LagIndicator latency={latency} />
            )} */}
        </ProfileInfo>
    ) : null

    return (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent={'flex-end'} gap="8px" width="100%">
            {progress}
            {playerInfo}
        </Box>
    )
}
