import { useCallback, useEffect } from 'react'
// import { useStoreState } from '../../../../../store/hooks'
import useScript from '../../../hooks/useScript'
import { LoginState, UserData } from '../../GlobalHeader'
import {
    AcceptRematchEvent,
    AnalyticsEventMap,
    ClickVideoEvent,
    CloseGuestWelcomeEvent,
    CompleteLoginEvent,
    CompleteSignupEvent,
    CompletedPuzzleEvent,
    ComputerChallengeEvent,
    ConfirmEmailEvent,
    CreateGuestAccountEvent,
    CustomChallengeEvent,
    EnterAutoPoolEvent,
    ExitAutoPoolEvent,
    GameFinishedEvent,
    GameStartedEvent,
    LogoutEvent,
    OfferAbortEvent,
    OfferDrawEvent,
    OfferRematchEvent,
    OpenLoginEvent,
    OpenSignupEvent,
    RequestResendEvent,
    ResignEvent,
    SignupReachSecondPageEvent,
    TimeInQueueEvent,
    analyticsManager,
} from './AnalyticsManager'
import { getHighestUserGroupName } from './utils'

declare global {
    interface Window {
        dataLayer: Array<any>
    }
}

type Props = {
    env: string
    gtmId: string
    userData?: UserData
    loginState: LoginState
}

export const GoogleAnalyticsServiceComponent = ({ env, userData, gtmId, loginState }: Props) => {
    const sendCustomEvent = (eventData: any) => {
        const data = {
            ...eventData,
            userId: userData?.id || '',
            userName: userData?.user_name || '',
        }
        window.dataLayer.push(data)
        env === 'dev' && console.log(`[GoogleAnalyticsService] ${eventData.event || ''} event data:`, data)
    }

    useEffect(() => {
        const eventHandlers: { [key in keyof AnalyticsEventMap]?: Function } = {
            openSignup: (e: OpenSignupEvent) => {
                sendCustomEvent({ ...e, event: 'openSignup' })
            },
            openLogin: (e: OpenLoginEvent) => {
                sendCustomEvent({ ...e, event: 'openLogin' })
            },
            createGuestAccount: (e: CreateGuestAccountEvent) => {
                sendCustomEvent({ event: 'createGuestAccount' })
            },
            closeGuestWelcome: (e: CloseGuestWelcomeEvent) => {
                sendCustomEvent({ event: 'closeGuestWelcome' })
            },
            signupReachSecondPage: (e: SignupReachSecondPageEvent) => {
                sendCustomEvent({ ...e, event: 'signupReachSecondPage' })
            },
            completeSignup: (e: CompleteSignupEvent) => {
                sendCustomEvent({
                    signupCountry: e.country,
                    isOver12: e.isOver12,
                    authType: e.authType,
                    event: 'completeSignup',
                })
            },
            requestResend: (e: RequestResendEvent) => {
                sendCustomEvent({ event: 'requestResend' })
            },
            confirmEmail: (e: ConfirmEmailEvent) => {
                sendCustomEvent({ event: 'confirmEmail' })
            },
            completeLogin: (e: CompleteLoginEvent) => {
                sendCustomEvent({ ...e, event: 'completeLogin' })
            },
            logout: (e: LogoutEvent) => {
                sendCustomEvent({ event: 'logout' })
            },
            customChallenge: (e: CustomChallengeEvent) => {
                sendCustomEvent({ ...e, event: 'customChallenge' })
            },
            computerChallenge: (e: ComputerChallengeEvent) => {
                sendCustomEvent({ ...e, event: 'computerChallenge' })
            },
            enterAutoPool: (e: EnterAutoPoolEvent) => {
                sendCustomEvent({ ...e, event: 'enterAutoPool' })
            },
            exitAutoPool: (e: ExitAutoPoolEvent) => {
                sendCustomEvent({ ...e, event: 'exitAutoPool' })
            },
            timeInQueue: (e: TimeInQueueEvent) => {
                sendCustomEvent({ ...e, event: 'timeInQueue' })
            },
            gameStarted: (e: GameStartedEvent) => {
                sendCustomEvent({
                    ...e,
                    challengeString: e.challengeString || '',
                    difficulty: e.difficulty || '',
                    botId: e.botId || '',
                    botName: e.botName || '',
                    event: 'gameStarted',
                })
            },
            resign: (e: ResignEvent) => {
                sendCustomEvent({ event: 'resign' })
            },
            offerDraw: (e: OfferDrawEvent) => {
                sendCustomEvent({ event: 'offerDraw' })
            },
            offerAbort: (e: OfferAbortEvent) => {
                sendCustomEvent({ event: 'offerAbort' })
            },
            offerRematch: (e: OfferRematchEvent) => {
                sendCustomEvent({ event: 'offerRematch' })
            },
            acceptRematch: (e: AcceptRematchEvent) => {
                sendCustomEvent({ event: 'acceptRematch' })
            },
            gameFinished: (e: GameFinishedEvent) => {
                sendCustomEvent({ ...e, event: 'gameFinished' })
            },
            clickVideo: (e: ClickVideoEvent) => {
                sendCustomEvent({ ...e, event: 'clickVideo' })
            },
            completedPuzzle: (e: CompletedPuzzleEvent) => {
                sendCustomEvent({ ...e, event: 'completedPuzzle' })
            },
            reward: (e: any) => {
                sendCustomEvent({ ...e, event: 'reward' })
            },
        }

        console.log('[GoogleAnalyticsService] initialize google analytics service')

        // Add listeners using the stored references
        Object.entries(eventHandlers).forEach(([event, handler]) => {
            analyticsManager.addEventListener(event as any, handler as any)
        })

        return () => {
            // Remove listeners using the stored references
            Object.entries(eventHandlers).forEach(([event, handler]) => {
                analyticsManager.removeEventListener(event as any, handler as any)
            })
        }
    }, [userData?.id, userData?.groups])

    useEffect(() => {
        const userId = userData?.id
        const userType = userId ? getHighestUserGroupName(userData.groups) : 'visitor'
        window.dataLayer.push({ userType, userId: userId || '' })
    }, [userData?.id, userData?.groups])

    useEffect(() => {
        window.dataLayer.push({ env })
    }, [env])

    const callback = useCallback((err?: string | Event) => {
        if (err) return console.error(err)
        window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    }, [])

    useScript(
        'gtm',
        `https://www.googletagmanager.com/gtm.js?id=${gtmId}`,
        {
            async: true,
            shouldLoad: loginState !== LoginState.INITIALIZING,
        },
        callback,
    )

    return null
}
